import AuthLayout from "../../layouts/AuthLayout";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ManualButton from "../../components/buttons/ManualButton";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import validationMessage from "../../constants/validationMessage";
import {
  requestSendSignInCode,
  responseSendSignInCode,
  responseVerifyCode,
} from "../../interfaces/authentication";
import authenticationService from "../../services/authenticationService";
import OtpInput from "react-otp-input";
import { LoadingButton } from "@mui/lab";
import Countdown from "react-countdown";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  email: yup
    .string()
    .required(validationMessage.required)
    .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
      message: validationMessage.email,
    }),
  password: yup.string().required(),
});

const SignInPage = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [accountData, setAccountData] = useState<requestSendSignInCode>({
    email: "",
    password: "",
  });
  const [otp, setOtp] = useState("");
  const [countdownTime, setCountdownTime] = useState<number>(0);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<requestSendSignInCode>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const sendSignInCode: SubmitHandler<requestSendSignInCode> = async (
    formData
  ) => {
    setIsLoading(true);
    try {
      let response = await authenticationService.signIn(formData);
      let authData: responseSendSignInCode = response.data;
      setAccountData(formData);
      if (authData.hasVerify) {
        Swal.fire({
          icon: "success",
          html: "Sent verify code successfully <br>Please check the mailbox!",
          showCloseButton: true,
        });
        setStep(2);
        setCountdownTime(Date.now() + authData.expiration * 60 * 1000);
      } else {
        localStorage.setItem("accessToken", authData.token);
        localStorage.setItem("user", JSON.stringify(authData.user));
        localStorage.setItem("email", authData.user?.email ?? "");
        navigate("/");
      }
    } catch (e: any) {
      Swal.fire({
        icon: "error",
        width: 700,
        text: e.response?.data?.message,
        showCloseButton: true,
      });
    }
    setIsLoading(false);
  };

  const resendVerifyCode = async () => {
    setIsLoading(true);
    setCountdownTime(0);
    try {
      Swal.fire({
        icon: "success",
        html: "Sent verify code successfully<br>Please check the mailbox!",
        showCloseButton: true,
      });
      let response = await authenticationService.signIn(accountData);
      let authData: responseSendSignInCode = response.data;
      setCountdownTime(Date.now() + authData.expiration * 60 * 1000);
    } catch (e) {
      setStep(1);
    }
    setIsLoading(false);
  };

  const verifySignIn = async () => {
    setIsLoading(true);
    let dataVerify = { ...accountData, verifyCode: otp };
    try {
      let response = await authenticationService.verifySignInCode(dataVerify);
      let authData: responseVerifyCode = response.data;
      localStorage.setItem("user", JSON.stringify(authData.user));
      localStorage.setItem("accessToken", authData.token);
      localStorage.setItem("email", authData.user.email);
      navigate("/");
    } catch (e: any) {
      Swal.fire({
        icon: "error",
        text: e.response?.data?.message,
        showCloseButton: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout>
      <Box className={"main-layout"}>
        {step === 1 && (
          <>
            <h1 className="text-center title-auth  mt-0">
              Master TKG Platform Login
            </h1>
            <form onSubmit={handleSubmit(sendSignInCode)}>
              <TextField
                className="mb-30"
                disabled={isLoading}
                {...register("email")}
                error={errors.email !== undefined}
                helperText={
                  errors.email !== undefined ? errors.email.message : ""
                }
                fullWidth
                defaultValue={localStorage.getItem("email")}
                label={"Enter your email"}
              />
              <FormControl
                fullWidth
                disabled={isLoading}
                variant="outlined"
                error={errors.password !== undefined}
              >
                <InputLabel
                  error={errors.password !== undefined}
                  htmlFor="outlined-adornment-password"
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  // className="mb-30"
                  {...register("password")}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  error={errors.password !== undefined}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {errors.password !== undefined && (
                  <FormHelperText>{errors.password.message}</FormHelperText>
                )}
              </FormControl>
              <Box className={"footer-form"} sx={{mt: "30px"}}>
                <Link className={"link-tkg"} to={"/forgot-password-request"}>
                  FORGOT PASSWORD?
                </Link>
                <ManualButton
                  text={"SUBMIT"}
                  type={"submit"}
                  isLoading={isLoading}
                />
              </Box>
            </form>
          </>
        )}
        {step === 2 && (
          <>
            <h1 className="text-center title-auth  mt-0">TKG Platform Login</h1>
            <form onSubmit={handleSubmit(sendSignInCode)}>
              <InputLabel style={{ marginBottom: "10px" }} htmlFor="otp-code">
                Please enter the verify code
              </InputLabel>
              <FormControl fullWidth disabled={isLoading} variant="outlined">
                <OtpInput
                  containerStyle={{
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                  inputStyle={{
                    width: "15%",
                    margin: "0",
                    height: "45px",
                    fontSize: "25px",
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                  renderInput={(props) => (
                    <input disabled={isLoading} {...props} />
                  )}
                />
              </FormControl>
              <Box className={"footer-form"} mt={"30px"}>
                <Button
                  style={{ opacity: otp.length ? 1 : 0 }}
                  disabled={!otp.length}
                  onClick={() => setOtp("")}
                >
                  Clear Code
                </Button>
                <LoadingButton
                  className={"manual-button"}
                  disabled={otp.length < 5}
                  type={"submit"}
                  startIcon={<span></span>}
                  loadingPosition={"start"}
                  loading={isLoading}
                  onClick={verifySignIn}
                >
                  SUBMIT
                </LoadingButton>
              </Box>
              {countdownTime > 0 && (
                <Box style={{ marginTop: "20px" }}>
                  <Countdown
                    date={countdownTime}
                    renderer={({ hours, minutes, seconds, completed }) => {
                      if (completed) {
                        return (
                          <span
                            style={{ color: "#0094d3", cursor: "pointer" }}
                            onClick={resendVerifyCode}
                          >
                            Get new verify code
                          </span>
                        );
                      } else {
                        return (
                          <>
                            You can get new verify code after:{" "}
                            <span
                              onChange={() => console.log(1)}
                              style={{ color: "#0094d3" }}
                            >
                              0{minutes}:
                              {seconds < 10 ? "0" + seconds : seconds}
                            </span>
                          </>
                        );
                      }
                    }}
                  />
                </Box>
              )}
            </form>
          </>
        )}
        <Box className={"wrap-help"}>
          <Box className={"help-service"}>
            <p className={"mb-0 mt-0"}>Need Help?</p>
            <a href={`tel:${process.env.REACT_APP_TELL_HELP}`}>
              {process.env.REACT_APP_TELL_HELP}
            </a>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default SignInPage;
