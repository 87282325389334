import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { requestContact, requestEditContact } from "../interfaces/contactCRM";

const contactService = {
  getList: async () => {
    return await apiInstance.get(endPointAPI.CONTACT_CRM.GET_LIST);
  },
  getDetail: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_DETAIL + `/${payload.id}`
    );
  },
  createContact: async (payload: requestContact) => {
    return await apiInstance.post(endPointAPI.CONTACT_CRM.CREATE, payload);
  },
  updateContact: async (payload: requestContact, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.CONTACT_CRM.UPDATE}/${id}`,
      payload
    );
  },
  deleteContact: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.CONTACT_CRM.DELETE + `/${payload.id}`
    );
  },
  //  End contact
  storeNote: async (payload: { content: string }, contact_id: number) => {
    return await apiInstance.post(
      `${endPointAPI.CONTACT_CRM.STORE_NOTE}/${contact_id}`,
      payload
    );
  },
  sendEmail: async (
    payload: { subject: string; content: string },
    contact_id: number
  ) => {
    return await apiInstance.post(
      `${endPointAPI.CONTACT_CRM.SEND_EMAIL}/${contact_id}`,
      payload
    );
  },
  getNotes: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_NOTES + `/${payload.id}`
    );
  },
  getHistory: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_HISTORY + `/${payload.id}`
    );
  },
  getEmailSent: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.GET_EMAIL_SENT + `/${payload.id}`
    );
  },
};

export default contactService;
