import { Add } from "@mui/icons-material";
import {
  Box,
  Button
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import LoadingPage from "../../components/Loading";
import ContentLayout from "../../components/layouts/ContentLayout";
import { CompanyIndex } from "../../interfaces/companyCRM";
import companyService from "../../services/companyService";
import CreateCompanyModal from "../company/CreateCompanyModal";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

const CompanyPage = () => {
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [listCompany, setListCompany] = useState<CompanyIndex[]>([]);
 
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleID, setSelectedRoleID] = useState(0);
    const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  
  const getListCompany = async () => {
    setLoading(true)
    try {
      const response = await companyService.getList();
      const user: CompanyIndex[] = response.data;
      setListCompany(user);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false)
  };

  useEffect(() => {
    getListCompany()
  }, []);
  
  const clearFormCreate = () => {
    setOpenDialogCreate(false);
    setViewMode(false);
    setSelectedRoleID(0);
    setRoleName("");
  };

  const listData = useMemo(() => {
    //Current Page slice
    let baseData = listCompany;

    if (filterText)
      baseData = baseData.filter((e: any) =>
        e?.name?.toLowerCase().trim().includes(filterText?.toLowerCase().trim())
      );

    return baseData?.slice(
      (page - 1) * perPage,
      (page - 1) * perPage + perPage
    );
  }, [listCompany, page, perPage, filterText]);

  return (
    <>
   {
      loading ? <Box
      sx={{
        width: `calc(100vw - 360px)`,
        overflow: "hidden",
        m: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "82vh",
        background: "#f5f5f5",
      }}
    >
      <LoadingPage />
    </Box> :
      <ContentLayout
        heading="Company Page"
        extraActions={
          <Button
            variant={"contained"}
            size="medium"
            onClick={() => {
              clearFormCreate()
              setOpenDialogCreate(true);
            }}
            sx={{ textTransform: "capitalize" }}
            className="bg-color-main"
            endIcon={<Add />}
          >
            Add Company
          </Button>
        }
        data={listCompany}
        getListTable={getListCompany}
        tableHeading={<TableHeading />}
        tableContent={listData.map((e: any, i: number) => (
          <TableItem
            key={i}
            data={e}
            dataKey={i}
            childrenCallback={getListCompany}
          />
        ))}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        filterText={filterText}
        setFilterText={setFilterText}
      />}
      {openDialogCreate && (
          <CreateCompanyModal setOpenCreateCompany={setOpenDialogCreate} openCreateCompany={openDialogCreate} getListCompany={getListCompany} />
      )}
    </>
  );
};

export default CompanyPage;
