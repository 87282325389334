import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Phone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import AvatarUploader from "../../components/AvatarUploader";
import validationMessage from "../../constants/validationMessage";
import { CompanyIndex } from "../../interfaces/companyCRM";
import { requestContact } from "../../interfaces/contactCRM";
import companyService from "../../services/companyService";
import contactService from "../../services/contactService";
import dataSelect from "../../utils/data/dataSelect";
interface Props {
  openCreateCompany: boolean;
  setOpenCreateCompany: (data: boolean) => void;
  getListContact: () => void;
}
const cssBodyModal = {
  pr: 2,
  // pl: 1,
  // py: 2,
  m: 0,
  // height: '80%',
  height: "calc(100vh - 11rem)",
  overflow: "hidden",
  overflowY: "auto",
  // background: '#fff',
};

const { country, titles } = dataSelect;

const CreateContactModal = ({
  openCreateCompany,
  setOpenCreateCompany,
  getListContact,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [listCompany, setListCompany] = useState<CompanyIndex[]>([]);
  const refButtonSubmit = useRef<HTMLButtonElement>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object({
    title: yup.string().required(validationMessage.required),
    companyId: yup
      .number()
      .required(validationMessage.required)
      .transform((value) => (isNaN(value) ? undefined : value)),
    firstName: yup.string().required(validationMessage.required),
    lastName: yup.string().required(validationMessage.required),
    middleName: yup.string(),
    email: yup
      .string()
      .required(validationMessage.required)
      .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
        message: validationMessage.email,
      }),
    secondEmail: yup.string(),
    // .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
    //   message: validationMessage.email,
    // }),
    dob: yup.string().required(validationMessage.required),
    phoneNumber: yup
      .string()
      .required(validationMessage.required)
      .matches(phoneRegExp, "Phone number is not valid"),
    position: yup.string().required(validationMessage.required),
    department: yup.string().required(validationMessage.required),
    countryCode: yup.string().required(validationMessage.required),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<requestContact>({
    defaultValues: {
      dob: dayjs().format("YYYY-MM-DD"),
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getListCompany = async () => {
    try {
      const response = await companyService.getList();
      const user: CompanyIndex[] = response.data;
      setListCompany(user);
    } catch (e: any) {
      console.log(e);
    }
  };
  useEffect(() => {
    getListCompany();
  }, []);

  const createContact: SubmitHandler<requestContact> = async (formData) => {
    setIsLoading(true);

    console.log(formData);

    try {
      let response = await contactService.createContact({
        ...formData,
        avatarUrl: avatar?.length > 0 ? avatar[0] : "",
      });
      Swal.fire("", response?.data?.message, "success");
      getListContact();
      setOpenCreateCompany(false);
      reset();
    } catch (e: any) {
      Swal.fire({
        icon: "error",
        width: 700,
        text: e?.response?.data?.message,
        showCloseButton: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openCreateCompany}
      onClose={() => setOpenCreateCompany(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
          bgcolor: "#0094d3",
          color: "#fff",
        }}
      >
        CRM - Contact
        <IconButton
          aria-label="close"
          onClick={() => setOpenCreateCompany(false)}
        >
          <Close sx={{ color: "#fff" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: "#EFECEC", p: 0 }}>
        <Paper
          sx={{
            width: `calc(100vw - 310px)`,
            overflow: "hidden",
            m: 2,
            height: "fit-content",
          }}
        >
          <form onSubmit={handleSubmit(createContact)}>
            <Grid
              container
              spacing={1}
              sx={cssBodyModal}
              justifyContent="space-around"
            >
              <Grid item xs={12} md={12}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "0.75rem",
                    boxShadow: "unset",
                  }}
                >
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, md: 3 }}
                    mb={3}
                  >
                    <Grid
                      item
                      xs={12}
                      md={3}
                      display={"flex"}
                      justifyContent={"center"}
                      sx={{ mb: "0 !important" }}
                      borderRadius={"50%"}
                    >
                      <AvatarUploader
                        listImages={avatar}
                        setListImages={setAvatar}
                        page={"contact-crm"}
                        widthImg={200}
                        heightImg={300}
                        idBtnUpload={"avatar-upload"}
                        classes={"d-flex"}
                        isEdit={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid
                        container
                        rowSpacing={3}
                        sx={{ marginBottom: 0 }}
                        columnSpacing={{ xs: 1, md: 3 }}
                        mb={3}
                      >
                        {" "}
                        <Grid item xs={12} md={3}>
                          <Controller
                            control={control}
                            name="title"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="title"
                                  getOptionLabel={(option) => option.label}
                                  onChange={(e, value) => {
                                    onChange(value.value);
                                  }}
                                  size="small"
                                  options={titles}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Title "
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.title !== undefined}
                                      helperText={
                                        errors.title !== undefined
                                          ? errors.title.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            disabled={isLoading}
                            {...register("firstName")}
                            error={errors.firstName !== undefined}
                            helperText={
                              errors.firstName !== undefined
                                ? errors.firstName.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={""}
                            fullWidth
                            label={"First Name"}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            disabled={isLoading}
                            {...register("middleName")}
                            error={errors.middleName !== undefined}
                            helperText={
                              errors.middleName !== undefined
                                ? errors.middleName.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={""}
                            fullWidth
                            label={"Middle Name"}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            disabled={isLoading}
                            {...register("lastName")}
                            error={errors.lastName !== undefined}
                            helperText={
                              errors.lastName !== undefined
                                ? errors.lastName.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={""}
                            fullWidth
                            label={"Last Name"}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            control={control}
                            name="countryCode"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  id="countryCode"
                                  getOptionLabel={(option) =>
                                    `(${option.value}) ${option.label}`
                                  }
                                  onChange={(e, value) => {
                                    onChange(value?.value);
                                    if (value) {
                                      setPhoneNumber(value?.value);
                                    }
                                  }}
                                  options={country}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country Code "
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.countryCode !== undefined}
                                      helperText={
                                        errors.countryCode !== undefined
                                          ? errors.countryCode.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Phone "
                            id="phoneNumber"
                            {...register("phoneNumber")}
                            error={errors.phoneNumber !== undefined}
                            helperText={
                              errors.phoneNumber !== undefined
                                ? errors.phoneNumber.message
                                : ""
                            }
                            defaultValue={""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isLoading}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <p>{phoneNumber}</p>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            disabled={isLoading}
                            {...register("email")}
                            error={errors.email !== undefined}
                            helperText={
                              errors.email !== undefined
                                ? errors.email.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={""}
                            fullWidth
                            label={"Email"}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            disabled={isLoading}
                            {...register("secondEmail")}
                            error={errors.secondEmail !== undefined}
                            helperText={
                              errors.secondEmail !== undefined
                                ? errors.secondEmail.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={""}
                            fullWidth
                            label={"Second Email"}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} md={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ position: "relative" }}>
                              <Controller
                                control={control}
                                name="dob"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    sx={{ width: "100%", height: "45px" }}
                                    label="Date of Birth (*)"
                                    format="DD/MM/YYYY"
                                    value={dayjs(value)}
                                    views={["year", "month", "day"]}
                                    onChange={(value: any) => {
                                      if (value) {
                                        onChange(value.format("YYYY-MM-DD"));
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.dob !== undefined && (
                                <small
                                  style={{
                                    color: "#d32f2f",
                                    position: "absolute",
                                    left: "10px",
                                    bottom: "-13px",
                                  }}
                                >
                                  {errors.dob.message}
                                </small>
                              )}
                            </Box>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Position "
                            id="position"
                            {...register("position")}
                            error={errors.position !== undefined}
                            helperText={
                              errors.position !== undefined
                                ? errors.position.message
                                : ""
                            }
                            defaultValue={""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Department "
                            id="department"
                            {...register("department")}
                            error={errors.department !== undefined}
                            helperText={
                              errors.department !== undefined
                                ? errors.department.message
                                : ""
                            }
                            defaultValue={""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            control={control}
                            name="companyId"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="companyId"
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e, value) => {
                                    onChange(value.id);
                                  }}
                                  size="small"
                                  options={listCompany}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Company "
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.companyId !== undefined}
                                      helperText={
                                        errors.companyId !== undefined
                                          ? errors.companyId.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <LoadingButton
              style={{ display: "none" }}
              ref={refButtonSubmit}
              className={"manual-button"}
              disabled={isLoading}
              type={"submit"}
              startIcon={<span></span>}
              loadingPosition={"start"}
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </form>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={() => refButtonSubmit.current?.click()}
          className={"manual-button"}
          disabled={isLoading}
          type={"submit"}
          startIcon={<span></span>}
          loadingPosition={"start"}
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Drawer>
  );
};

export default CreateContactModal;
