const endPointAPI = {
    AUTHENTICATION: {
        SEND_SIGN_IN_CODE: "/authentication/send-sign-in-code",
        VERIFY_SIGN_IN_CODE: "/authentication/verify-sign-in-code",
        SEND_FORGOT_PASSWORD: "/authentication/send-forgot-password-request",
        VERIFY_FORGOT_PASSWORD_TOKEN: "/authentication/verify-forgot-password-token",
        UPDATE_PASSWORD: "/authentication/forgot-password"
    },
    USER: {
        CURRENT: "/user/current"
    },
    PERMISSION: {
        GET: "/role-and-permission/get-permission",
        CREATE: "/role-and-permission/store-permission",
        UPDATE: "/role-and-permission/update-permission",
        DELETE: "/role-and-permission/delete-permission",
    }, 
    ROLE: {
        GET: "/role-and-permission/get-role",
        CREATE: "/role-and-permission/store-role",
        UPDATE: "/role-and-permission/update-role",
        DELETE: "/role-and-permission/delete-role",
    }, 
    PLATFORM_USER: {
        GET: "/user/index",
        CREATE: "/user/store",
        UPDATE: "/user/update",
        UPDATE_STATUS: "/user/update-status",
        DELETE: "/user/delete",
    }, 

    IMAGE: {
        UPLOAD_IMAGE: "api/components/upload-image"
    },

    COMPANY_CRM: {
        GET_LIST: "/company",
        GET_DETAIL: "/company/show",
        CREATE: "/company/store",
        UPDATE: "/company/update",
        DELETE: "/company/delete",
        STORE_NOTE: "/company/store-note",
        SEND_EMAIL: "/company/send-email",
        GET_NOTES: "/company/notes",
        GET_HISTORY: "/company/history",
        GET_EMAIL_SENT: "/company/email-sent",
        GET_LIST_DOCUMENTS: "/company/documents",
        UPLOAD_DOCS: "/company/upload-document",
        DELETE_DOCS: "/company/delete-document",
    },
    CONTACT_CRM: {
        GET_LIST: "/contact",
        GET_DETAIL: "/contact/show",
        CREATE: "/contact/store",
        UPDATE: "/contact/update",
        DELETE: "/contact/delete",
        STORE_NOTE: "/contact/store-note",
        SEND_EMAIL: "/contact/send-email",
        GET_NOTES: "/contact/notes",
        GET_HISTORY: "/contact/history",
        GET_EMAIL_SENT: "/contact/email-sent",
        CONTACT_COMPANY: "/contact/company",
    },
    
    SOLUTION: {
        GET_LIST: "solution"
    }
}

export default endPointAPI;
