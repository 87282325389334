import { Grid, TextField, Autocomplete, Divider, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import dataSelect from "../../utils/data/dataSelect";

interface Props {
  register: any;
  errors: any;
  isLoading: boolean;
  control: any;
  isEdit?: boolean
}
const AddressComponent = ({ register, errors, isLoading, control, isEdit = true }: Props) => {
  return (
    <>
    <Grid item xs={12}>
                      <Divider textAlign="left">
                        <Typography
                          sx={{ fontWeight: "bold", color: "#0094d3" }}
                        >
                          BILLING ADDRESS
                        </Typography>
                      </Divider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                              <TextField
                                size="small"
                                fullWidth
                                label="Address "
                                id="address"
                                {...register("address")}
                                error={errors.address !== undefined}
                                helperText={
                                  errors.address !== undefined
                                    ? errors.address.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ readOnly: !isEdit }}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                size="small"
                                fullWidth
                                label="City "
                                id="city"
                                {...register("city")}
                                error={errors.city !== undefined}
                                helperText={
                                  errors.city !== undefined
                                    ? errors.city.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ readOnly: !isEdit }}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                size="small"
                                fullWidth
                                label="Region "
                                id="region"
                                {...register("region")}
                                error={errors.region !== undefined}
                                helperText={
                                  errors.region !== undefined
                                    ? errors.region.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ readOnly: !isEdit }}
                                disabled={isLoading}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                size="small"
                                fullWidth
                                label="Postcode "
                                id="postcode"
                                {...register("postcode")}
                                error={errors.postcode !== undefined}
                                helperText={
                                  errors.postcode !== undefined
                                    ? errors.postcode.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ readOnly: !isEdit }}
                                disabled={isLoading}
                              />
                            </Grid>{" "}
                            <Grid item xs={12} md={3}>
                              <Controller
                                control={control}
                                name="country"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                      disablePortal
                                      id="country"
                                      value={dataSelect.country.find(
                                        (item) => item?.code === value
                                      )}
                                      onChange={(e, value) =>
                                        onChange(value?.code)
                                      }
                                      size="small"
                                      options={dataSelect.country}
                                      readOnly={!isEdit}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Country "
                                          // defaultValue={""}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={errors.country !== undefined}
                                          helperText={
                                            errors.country !== undefined
                                              ? errors.country.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                            </Grid>
    </>
  );
};

export default AddressComponent;
