import AuthLayout from "../../layouts/AuthLayout";
import {Box, TextField} from "@mui/material";
import ManualButton from "../../components/buttons/ManualButton";
import {Link, useNavigate} from "react-router-dom";
import { SubmitHandler, useForm} from "react-hook-form";
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup";
import validationMessage from "../../constants/validationMessage";
import {useState} from "react";
import authenticationService from "../../services/authenticationService";
import Swal from "sweetalert2";

interface Inputs {
    email: string
}

const validationSchema = yup.object({
    email: yup.string().required(validationMessage.required).matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {message: validationMessage.email}),
})

const ForgotPasswordRequestPage = () => {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const {register, handleSubmit, formState: {errors}} = useForm<Inputs>({
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    const sendForgotPasswordLink: SubmitHandler<Inputs> = async (data) => {
        setLoading(true);
        try {
             await authenticationService.sendForgotPassword(data);
            Swal.fire({
                icon: 'success',
                html: "Sent reset password request successfully<br>Please check the mailbox!",
                showCloseButton: true
            })
            navigate('/sign-in')
        } catch (e : any){
            Swal.fire({
                icon: 'error',
                text: e.response?.data?.message,
                showCloseButton: true
            })
        }
    }

    return <AuthLayout>
        <Box className={"main-layout"}>
            <h1 className="text-center title-auth  mt-0">Forgot Password Request</h1>
            <form onSubmit={handleSubmit(sendForgotPasswordLink)}>
                <TextField {...register("email")} className="mb-30"
                           error={errors.email !== undefined} id="email" fullWidth label={"Enter your email"}
                           helperText={errors.email !== undefined ? errors.email.message : ""}
                />
                <Box className={"footer-form"}>
                    <Link to={'/sign-in'}>Back to Sign In</Link>
                    <ManualButton text={"SUBMIT"} type={"submit"} isLoading={loading}/>
                </Box>
            </form>

            <Box className={"wrap-help"}>
                <Box className={"help-service"}>
                    <p className={"mb-0 mt-0"}>Need Help?</p>
                    <a href={`tel:${process.env.REACT_APP_TELL_HELP}`}>{process.env.REACT_APP_TELL_HELP}</a>
                </Box>
            </Box>

        </Box>
    </AuthLayout>
}

export default ForgotPasswordRequestPage;
