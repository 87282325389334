import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Phone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import AvatarUploader from "../../components/AvatarUploader";
import validationMessage from "../../constants/validationMessage";
import { requestCompany } from "../../interfaces/companyCRM";
import { Plan, Solution } from "../../interfaces/solutionAndPlanInterface";
import companyService from "../../services/companyService";
import solutionService from "../../services/solutionService";
import dataSelect from "../../utils/data/dataSelect";
import { MuiTelInput } from "mui-tel-input";
import AddressComponent from "../../components/AddressComponent.tsx";
interface Props {
  openCreateCompany: boolean;
  setOpenCreateCompany: (data: boolean) => void;
  getListCompany: () => void;
}
const cssBodyModal = {
  pr: 2,
  m: 0,
  height: "calc(100vh - 11rem)",
  overflow: "hidden",
  overflowY: "auto",
};

const { country, companyType, status, phoneType } = dataSelect;

const CreateCompanyModal = ({
  openCreateCompany,
  setOpenCreateCompany,
  getListCompany,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [listSolution, setListSolution] = useState<Solution[]>([]);
  const [listPlan, setListPlan] = useState<Plan[]>([]);

  const refButtonSubmit = useRef<HTMLButtonElement>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object({
    name: yup.string().required(validationMessage.required),
    solutionId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value)),
    planId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value)),
    status: yup.string().required(validationMessage.required),
    type: yup.string().required(validationMessage.required),
    mainEmail: yup
      .string()
      .required(validationMessage.required)
      .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
        message: validationMessage.email,
      }),
    mainEmailType: yup.string().required(validationMessage.required),
    secondEmail: yup.string(),
    // .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
    //   message: validationMessage.email,
    // }),
    secondEmailType: yup.string(),
    countryCode: yup.string().required(validationMessage.required),
    phoneNumber: yup
      .string()
      .required(validationMessage.required)
      .matches(phoneRegExp, "Phone number is not valid"),
    phoneType: yup.string().required(validationMessage.required),
    countryCodeSecond: yup
      .string()
      .transform((value) => (value === null ? "" : value)),
    phoneNumberSecond: yup
      .string()
      .transform((value) => (value === null ? "" : value)),
    phoneTypeSecond: yup
      .string()
      .transform((value) => (value === null ? "" : value)),
    websiteUrl: yup.string().required(validationMessage.required),
    address: yup.string().required(validationMessage.required),
    city: yup.string().required(validationMessage.required),
    country: yup.string().required(validationMessage.required),
    region: yup.string().required(validationMessage.required),
    postcode: yup.string().required(validationMessage.required),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<requestCompany>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getListSolution = async () => {
    try {
      const response = await solutionService.getList();
      const solutions: Solution[] = response.data;
      setListSolution(solutions);
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListSolution();
  }, []);

  const createCompany: SubmitHandler<requestCompany> = async (formData) => {
    setIsLoading(true);
    try {
      let response = await companyService.createCompany({
        ...formData,
        logoUrl: avatar?.length > 0 ? avatar[0] : "",
      });
      Swal.fire("", response?.data?.message, "success");
      setOpenCreateCompany(false);
      getListCompany();
      reset();
    } catch (e: any) {
      Swal.fire({
        icon: "error",
        width: 700,
        text: e?.response?.data?.message,
        showCloseButton: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openCreateCompany}
      onClose={() => setOpenCreateCompany(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
          bgcolor: "#0094d3",
          color: "#fff",
        }}
      >
        CRM - Company
        <IconButton
          aria-label="close"
          onClick={() => setOpenCreateCompany(false)}
        >
          <Close sx={{ color: "#fff" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: "#EFECEC", p: 0 }}>
        <Paper
          sx={{
            width: `calc(100vw - 310px)`,
            overflow: "hidden",
            m: 2,
            height: "fit-content",
          }}
        >
          <form onSubmit={handleSubmit(createCompany)}>
            <Grid
              container
              spacing={1}
              sx={cssBodyModal}
              justifyContent="space-around"
            >
              <Grid item xs={12} md={12}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "0.75rem",
                    boxShadow: "unset",
                  }}
                >
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, md: 3 }}
                    mb={3}
                  >
                    <Grid
                      item
                      xs={12}
                      md={3}
                      display={"flex"}
                      justifyContent={"center"}
                      sx={{ mb: "0 !important" }}
                      borderRadius={"50%"}
                    >
                      <AvatarUploader
                        listImages={avatar}
                        setListImages={setAvatar}
                        page={"company-crm"}
                        widthImg={200}
                        heightImg={300}
                        idBtnUpload={"other-upload"}
                        classes={"d-flex"}
                        isEdit={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid
                        container
                        rowSpacing={3}
                        sx={{ marginBottom: 0 }}
                        columnSpacing={{ xs: 1, md: 3 }}
                        mb={3}
                      >
                        <Grid item xs={12} md={4}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Name "
                            id="name"
                            {...register("name")}
                            error={errors.name !== undefined}
                            helperText={
                              errors.name !== undefined
                                ? errors.name.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={""}
                            disabled={isLoading}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="type"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="type"
                                  getOptionLabel={(option) => option.label}
                                  onChange={(e, value) => {
                                    onChange(value?.value);
                                  }}
                                  size="small"
                                  options={companyType}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Type "
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.type !== undefined}
                                      helperText={
                                        errors.type !== undefined
                                          ? errors.type.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Website Url "
                            id="websiteUrl"
                            {...register("websiteUrl")}
                            error={errors.websiteUrl !== undefined}
                            helperText={
                              errors.websiteUrl !== undefined
                                ? errors.websiteUrl.message
                                : ""
                            }
                            defaultValue={""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="solutionId"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  disableClearable
                                  id="solutionId"
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e, value) => {
                                    console.log(value);

                                    onChange(value?.id);
                                    if (value) {
                                      setListPlan(value?.plans);
                                    }
                                  }}
                                  options={listSolution}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Solution "
                                      // defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.solutionId !== undefined}
                                      helperText={
                                        errors.solutionId !== undefined
                                          ? errors.solutionId.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="planId"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  id="planId"
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e, value) => onChange(value?.id)}
                                  options={listPlan}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Plan "
                                      // defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.planId !== undefined}
                                      helperText={
                                        errors.planId !== undefined
                                          ? errors.planId.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="status"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="status"
                                  getOptionLabel={(option) => option.label}
                                  onChange={(e, value) => {
                                    onChange(value?.value);
                                  }}
                                  size="small"
                                  options={status}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status "
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.status !== undefined}
                                      helperText={
                                        errors.status !== undefined
                                          ? errors.status.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="countryCode"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  id="countryCode"
                                  getOptionLabel={(option) =>
                                    `(${option.value}) ${option.label}`
                                  }
                                  onChange={(e, value) => {
                                    onChange(value?.value);
                                    if (value) {
                                      setPhoneNumber(value?.value);
                                    }
                                  }}
                                  options={country}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country Code "
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.countryCode !== undefined}
                                      helperText={
                                        errors.countryCode !== undefined
                                          ? errors.countryCode.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} md={4}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Phone 1"
                            id="phoneNumber"
                            {...register("phoneNumber")}
                            error={errors.phoneNumber !== undefined}
                            helperText={
                              errors.phoneNumber !== undefined
                                ? errors.phoneNumber.message
                                : ""
                            }
                            defaultValue={""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isLoading}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <p>{phoneNumber}</p>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="phoneType"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  freeSolo
                                  autoSelect
                                  disablePortal
                                  disableClearable
                                  id="phoneType"
                                  getOptionLabel={(option) => option}
                                  onChange={(e, value) => {
                                    onChange(value);
                                  }}
                                  size="small"
                                  options={phoneType}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Type"
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.phoneType !== undefined}
                                      helperText={
                                        errors.phoneType !== undefined
                                          ? errors.phoneType.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="countryCodeSecond"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  id="countryCodeSecond"
                                  getOptionLabel={(option) =>
                                    `(${option.value}) ${option.label}`
                                  }
                                  onChange={(e, value) => {
                                    onChange(value?.value);
                                    if (value) {
                                      setPhoneNumber2(value?.value);
                                    }
                                  }}
                                  options={country}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country Code"
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={
                                        errors.countryCodeSecond !== undefined
                                      }
                                      helperText={
                                        errors.countryCodeSecond !== undefined
                                          ? errors.countryCodeSecond.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>{" "}
                        <Grid item xs={12} md={4}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Phone 2"
                            id="phoneNumberSecond"
                            {...register("phoneNumberSecond")}
                            error={errors.phoneNumberSecond !== undefined}
                            helperText={
                              errors.phoneNumberSecond !== undefined
                                ? errors.phoneNumberSecond.message
                                : ""
                            }
                            defaultValue={""}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isLoading}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <p>{phoneNumber2}</p>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            control={control}
                            name="phoneTypeSecond"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  freeSolo
                                  autoSelect
                                  disablePortal
                                  disableClearable
                                  id="phoneTypeSecond"
                                  getOptionLabel={(option) => option}
                                  onChange={(e, value) => {
                                    onChange(value);
                                  }}
                                  size="small"
                                  options={phoneType}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Type"
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={
                                        errors.phoneTypeSecond !== undefined
                                      }
                                      helperText={
                                        errors.phoneTypeSecond !== undefined
                                          ? errors.phoneTypeSecond.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>{" "}
                    {/* <Grid item xs={12} md={6}></Grid> */}
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        disabled={isLoading}
                        {...register("mainEmail")}
                        error={errors.mainEmail !== undefined}
                        helperText={
                          errors.mainEmail !== undefined
                            ? errors.mainEmail.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={""}
                        fullWidth
                        label={"Main Email"}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        control={control}
                        name="mainEmailType"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Autocomplete
                              freeSolo
                              autoSelect
                              disablePortal
                              disableClearable
                              id="mainEmailType"
                              getOptionLabel={(option) => option}
                              onChange={(e, value) => {
                                onChange(value);
                              }}
                              size="small"
                              options={phoneType}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type "
                                  defaultValue={""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={errors.mainEmailType !== undefined}
                                  helperText={
                                    errors.mainEmailType !== undefined
                                      ? errors.mainEmailType.message
                                      : ""
                                  }
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        disabled={isLoading}
                        {...register("secondEmail")}
                        error={errors.secondEmail !== undefined}
                        helperText={
                          errors.secondEmail !== undefined
                            ? errors.secondEmail.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={""}
                        fullWidth
                        label={"Email 2"}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        control={control}
                        name="secondEmailType"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Autocomplete
                              freeSolo
                              autoSelect
                              disablePortal
                              disableClearable
                              id="secondEmailType"
                              getOptionLabel={(option) => option}
                              onChange={(e, value) => {
                                onChange(value);
                              }}
                              size="small"
                              options={phoneType}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Type "
                                  defaultValue={""}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={errors.secondEmailType !== undefined}
                                  helperText={
                                    errors.secondEmailType !== undefined
                                      ? errors.secondEmailType.message
                                      : ""
                                  }
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <AddressComponent control={control} register={register} errors={errors} isLoading={isLoading} isEdit={true} />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <LoadingButton
              style={{ display: "none" }}
              ref={refButtonSubmit}
              className={"manual-button"}
              disabled={isLoading}
              type={"submit"}
              startIcon={<span></span>}
              loadingPosition={"start"}
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </form>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={() => refButtonSubmit.current?.click()}
          className={"manual-button"}
          disabled={isLoading}
          type={"submit"}
          startIcon={<span></span>}
          loadingPosition={"start"}
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Drawer>
  );
};

export default CreateCompanyModal;
