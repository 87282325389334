import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { Role, RolePermission, RoleToPermission } from "../../interfaces/roleAndPermissionInterface";
import roleService from "../../services/roleService";
import ModalAction from "./ModalAction";
import ModalView from "./ModalView";
import ShowHistory from "./ShowHistory";

const options = ["View", "Edit", "Delete", "View History"];

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));
interface Props {
  data: any;
  dataKey: number;
  childrenCallback: () => void;
  listPermissions: any[]
  rolePermissions: RoleToPermission[];
  setRolePermissions: (data: RoleToPermission[]) => void;
}
const TableItem = ({ data, dataKey, childrenCallback, listPermissions, rolePermissions, setRolePermissions }: Props) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [perMissionHistory, setPerMissionHistory] = useState<any[]>([]);
  const [openDialogShowHistory, setOpenDialogShowHistory] = useState(false);
  const handleDelete = (id: number) => {
    Swal.fire({
      icon: "question",
      title: "Warning!",
      text: "Delete this? You can't revert this.",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "red",
    }).then(async (res) => {
      if (res?.isConfirmed) {
        setDisabledBtn(true);
        const payload = {
          id: id,
        };
        try {
          const response = await roleService.deleteRole(payload);
          Swal.fire("", response?.data?.message, "success");
          childrenCallback();
        } catch (e: any) {
          Swal.fire("", e?.data?.message, "error");
        }
        setDisabledBtn(false);
      }
    });
  };

  const viewPermission = (item: Role) => {
    const roleToPermission = item.roleToPermissions;
    const dataRoleToPermission: RoleToPermission[] = [];
    listPermissions.forEach((item) => {
      const permissionItem = roleToPermission.find(
        (permission) => permission.permissionId === item.permissionId
      );
      if (!permissionItem) {
        const data: RoleToPermission = {
          permissionId: item.permissionId,
          hasView: item.hasView,
          hasUpdate: item.hasUpdate,
          hasDelete: item.hasDelete,
          hasCreate: item.hasCreate,
        };

        dataRoleToPermission.push(data);
      } else {
        const data: RoleToPermission = {
          permissionId: permissionItem.permissionId,
          hasView: permissionItem.hasView,
          hasUpdate: permissionItem.hasUpdate,
          hasDelete: permissionItem.hasDelete,
          hasCreate: permissionItem.hasCreate,
        };
        dataRoleToPermission.push(data);
      }
    });
    setRolePermissions(dataRoleToPermission);
    setShowDetail(true);
  };
  const showHistoryPermission = (item: Role) => {
    const data = item.roleHistoryEvents.map((item) => {
      let dataEvents: any = {
        oldValue: {},
        newValue: {},
      };
      const events = JSON.parse(item.events);

      Object.keys(events.newValue || {}).map((element, ind) => {
        if (typeof events.newValue[element] === "object") {
          const oldHistory = events.oldValue[element];
          const newHistory = events.newValue[element];

          newHistory.forEach((el: RolePermission) => {
            const exitOldValue = oldHistory.find(
              (item: RolePermission) => item.permissionId === el.permissionId
            );
            if (exitOldValue) {
              const permissionName = listPermissions.find(
                (item) => item.permissionId === el.permissionId
              );

              Object.assign(dataEvents.oldValue, {
                [`${permissionName?.name}`]: {
                  View: exitOldValue.hasView,
                  Update: exitOldValue.hasUpdate,
                  Delete: exitOldValue.hasDelete,
                  Create: exitOldValue.hasCreate,
                },
              });
              Object.assign(dataEvents.newValue, {
                [`${permissionName?.name}`]: {
                  View: el.hasView,
                  Update: el.hasUpdate,
                  Delete: el.hasDelete,
                  Create: el.hasCreate,
                },
              });
            } else {
              const permissionName = listPermissions.find(
                (item) => item.permissionId === el.permissionId
              );

              Object.assign(dataEvents.oldValue, {
                [`${permissionName?.name}`]: {
                  View: false,
                  Update: false,
                  Delete: false,
                  Create: false,
                },
              });
              Object.assign(dataEvents.newValue, {
                [`${permissionName?.name}`]: {
                  View: el.hasView,
                  Update: el.hasUpdate,
                  Delete: el.hasDelete,
                  Create: el.hasCreate,
                },
              });
            }
          });
        } else {
          Object.assign(dataEvents.oldValue, {
            [`${element}`]: events.oldValue[element],
          });
          Object.assign(dataEvents.newValue, {
            [`${element}`]: events.newValue[element],
          });
        }
      });
      return {
        ...item,
        events: JSON.stringify(dataEvents),
      };
    });
    setPerMissionHistory(data);
    setOpenDialogShowHistory(true);
  };
  const editRole = (item: Role) => {
    const roleToPermission = item.roleToPermissions;
    const dataRoleToPermission: RoleToPermission[] = [];
    listPermissions.forEach((item) => {
      const permissionItem = roleToPermission.find(
        (permission) => permission.permissionId === item.permissionId
      );
      if (!permissionItem) {
        const data: RoleToPermission = {
          permissionId: item.permissionId,
          hasView: item.hasView,
          hasUpdate: item.hasUpdate,
          hasDelete: item.hasDelete,
          hasCreate: item.hasCreate,
        };

        dataRoleToPermission.push(data);
      } else {
        const data: RoleToPermission = {
          permissionId: permissionItem.permissionId,
          hasView: permissionItem.hasView,
          hasUpdate: permissionItem.hasUpdate,
          hasDelete: permissionItem.hasDelete,
          hasCreate: permissionItem.hasCreate,
        };
        dataRoleToPermission.push(data);
      }
    });

    setRolePermissions(dataRoleToPermission);
    setIsEdit(true);
  };

  const handleClick = () => {
    switch (options[selectedIndex]) {
      case "View":
        return viewPermission(data);
      case "Edit":
        return editRole(data)
      case "Delete":
        return handleDelete(data?.id);
      case "View History":
        return showHistoryPermission(data);
      default:
        return;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    switch (options[index]) {
      case "View":
        return  viewPermission(data);
      case "Edit":
        return editRole(data)
      case "Delete":
        return handleDelete(data?.id);
      case "View History":
        return showHistoryPermission(data);
      default:
        return;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const renderPermissions = (data: Role) => {
    const listIdRolePermission = data.roleToPermissions.map(
      (item: RolePermission) => {
        if(item.hasCreate || item.hasUpdate || item.hasDelete || item.hasView) {
          return item.permissionId
        }
        }
    );

    const listRolePermission: string[] = [];
    listPermissions.forEach((permission) => {
      if (listIdRolePermission.includes(permission.permissionId)) {
        listRolePermission.push(permission.name);
      }
    });

    return (
      <Stack direction="row" spacing={1} flexWrap={"wrap"}>
        {listRolePermission.map((item, index) => (
          <Chip key={index} label={item} variant="outlined" size="small" />
        ))}
      </Stack>
    );
  }
  return (
    <TableRow key={data?.id} className="item-hover">
      <StyledTableCell align="left">{dataKey + 1}</StyledTableCell>
      <StyledTableCell align="left">{data?.name}</StyledTableCell>
      <StyledTableCell align="center">
        {moment(data.createdAt).format("DD-MMM-YYYY")}
      </StyledTableCell>
      <StyledTableCell align="center">
        {moment(data.updatedAt).format("DD-MMM-YYYY")}
      </StyledTableCell>
      <StyledTableCell align="center">
        {data?.userUpdate
          ? data?.userUpdate?.firstName + " " + data?.userUpdate?.lastName
          : data?.userCreate?.firstName + " " + data?.userCreate?.lastName}
      </StyledTableCell>
      <StyledTableCell align="center" sx={{maxWidth: "300px"}}>
        {renderPermissions(data)}
      </StyledTableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            color={options[selectedIndex] === "Delete" ? "error" : "inherit"}
            sx={{ boxShadow: "unset", borderRadius: "20px" }}
          >
            <Button
              disabled={disabledBtn}
              onClick={handleClick}
              sx={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
            >
              {options[selectedIndex]}
            </Button>
            <Button
              disabled={disabledBtn}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
          {isEdit && (
            <ModalAction
            listPermissions={listPermissions}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              data={data}
              parentCallback={childrenCallback}
              rolePermissions={rolePermissions}
              setRolePermissions={setRolePermissions} 
            />
          )}
          {showDetail && <ModalView listPermissions={listPermissions} data={data} showDetail={showDetail} setShowDetail={setShowDetail}  rolePermissions={rolePermissions}
              setRolePermissions={setRolePermissions} />}

          {openDialogShowHistory && (
            <ShowHistory
              openDialogShowHistory={openDialogShowHistory}
              setOpenDialogShowHistory={setOpenDialogShowHistory}
              data={perMissionHistory}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
