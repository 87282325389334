import { Add } from "@mui/icons-material";
import {
  Box,
  Button
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import LoadingPage from "../../components/Loading";
import ContentLayout from "../../components/layouts/ContentLayout";
import { ContactIndex } from "../../interfaces/contactCRM";
import contactService from "../../services/contactService";
import CreateContactModal from "../contact/CreateContactModal";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

const ContactPage = () => {
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [listContact, setListContact] = useState<ContactIndex[]>([]);
 
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleID, setSelectedRoleID] = useState(0);
    const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  
  const getListContact = async () => {
    setLoading(true)
    try {
      const response = await contactService.getList();
      const  contacts: ContactIndex[] = response.data;
      setListContact(contacts);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false)
  };

  useEffect(() => {
    getListContact()
  }, []);
  
  const clearFormCreate = () => {
    setOpenDialogCreate(false);
    setViewMode(false);
    setSelectedRoleID(0);
    setRoleName("");
  };

  const listData = useMemo(() => {
    //Current Page slice
    let baseData = listContact;

    if (filterText)
      baseData = baseData.filter((e: any) =>
        (`${e.firstName ? e.firstName : ""} ${
          e.middleName ? e.middleName : ""
        } ${e.lastName ? e.lastName : ""}`)?.toLowerCase().trim().includes(filterText?.toLowerCase().trim())
      );

    return baseData?.slice(
      (page - 1) * perPage,
      (page - 1) * perPage + perPage
    );
  }, [listContact, page, perPage, filterText]);

  return (
    <>
    {
      loading ? <Box
      sx={{
        width: `calc(100vw - 360px)`,
        overflow: "hidden",
        m: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "82vh",
        background: "#f5f5f5",
      }}
    >
      <LoadingPage />
    </Box> :<ContentLayout
        heading="Contact Page"
        extraActions={
          <Button
            variant={"contained"}
            size="medium"
            onClick={() => {
              clearFormCreate()
              setOpenDialogCreate(true);
            }}
            sx={{ textTransform: "capitalize" }}
            className="bg-color-main"
            endIcon={<Add />}
          >
            Add Contact
          </Button>
        }
        data={listContact}
        getListTable={getListContact}
        tableHeading={<TableHeading />}
        tableContent={listData.map((e: any, i: number) => (
          <TableItem
            key={i}
            data={e}
            dataKey={i}
            childrenCallback={getListContact}
          />
        ))}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        filterText={filterText}
        setFilterText={setFilterText}
      />
    }
      
      {openDialogCreate && (
          <CreateContactModal
          setOpenCreateCompany={setOpenDialogCreate}
          openCreateCompany={openDialogCreate}
          getListContact={getListContact}
        />
      )}
    </>
  );
};

export default ContactPage;
