import AuthLayout from "../../layouts/AuthLayout";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import ManualButton from "../../components/buttons/ManualButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../constants/validationMessage";
import { useEffect, useState } from "react";
import authenticationService from "../../services/authenticationService";
import Swal from "sweetalert2";
import * as React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordStrengthBar from "react-password-strength-bar";
import { responseSendSignInCode } from "../../interfaces/authentication";

interface Inputs {
  password: string;
  passwordConfirm: string;
}

const validationSchema = yup.object({
  password: yup
    .string()
    .required(validationMessage.required)
    .matches(
      new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
      ),
      { message: validationMessage.passwordRule }
    ),
  passwordConfirm: yup
    .string()
    .required(validationMessage.required)
    .oneOf([yup.ref("password")], validationMessage.passwordConfirmNotMatch),
});

const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [isVerifyToken, setIsVerifyToken] = useState(false);
  let { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const formData = watch();

  const updatePassword: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    try {
      let response = await authenticationService.updatePassword({
        token: token ?? "",
        password: data.password,
      });
      let authData: responseSendSignInCode = response.data;
      localStorage.setItem("accessToken", authData.token);
      localStorage.setItem("user", JSON.stringify(authData.user));
      navigate("/");
    } catch (e: any) {
      Swal.fire({
        icon: "error",
        text: e.response?.data?.message,
        showCloseButton: true,
      });
    }
  };

  useEffect(() => {
    if (!token) navigate("/sign-in");
    authenticationService
      .verifyForgotPasswordToken({ token: token ?? "" })
      .then((res) => {
        setIsVerifyToken(true);
      })
      .catch((e) => {
        console.log(e);
        // navigate('/sign-in');
      });
  }, []);

  return (
    <AuthLayout>
      {!isVerifyToken ? (
        <CircularProgress />
      ) : (
        <Box className={"main-layout"}>
          <h1 className="text-center title-auth  mt-0">Forgot Password</h1>
          <form onSubmit={handleSubmit(updatePassword)}>
            <FormControl
              fullWidth
              disabled={loading}
              variant="outlined"
              error={errors.password !== undefined}
            >
              <InputLabel
                error={errors.password !== undefined}
                htmlFor="outlined-adornment-password"
              >
                New password
              </InputLabel>
              <OutlinedInput
                {...register("password")}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                error={errors.password !== undefined}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New password"
              />
              {formData.password && (
                <PasswordStrengthBar password={formData.password} />
              )}
              {errors.password !== undefined && (
                <FormHelperText>{errors.password.message}</FormHelperText>
              )}
            </FormControl>

            <FormControl
            sx={{mt: "30px"}}
              fullWidth
              disabled={loading}
              variant="outlined"
              error={errors.passwordConfirm !== undefined}
            >
              <InputLabel
                error={errors.passwordConfirm !== undefined}
                htmlFor="outlined-adornment-password-confirm"
              >
                Password Confirmation
              </InputLabel>
              <OutlinedInput
                {...register("passwordConfirm")}
                id="outlined-adornment-password-confirm"
                type={showPasswordConfirm ? "text" : "password"}
                error={errors.passwordConfirm !== undefined}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password Confirmation"
              />
              {errors.passwordConfirm !== undefined && (
                <FormHelperText>
                  {errors.passwordConfirm.message}
                </FormHelperText>
              )}
            </FormControl>

            <Box className={"footer-form"} mt={"30px"}>
              <Link to={"/sign-in"}>Back to Sign In</Link>
              <ManualButton
                text={"SUBMIT"}
                type={"submit"}
                isLoading={loading}
              />
            </Box>
          </form>

          <Box className={"wrap-help"}>
            <Box className={"help-service"}>
              <p className={"mb-0 mt-0"}>Need Help?</p>
              <a href={`tel:${process.env.REACT_APP_TELL_HELP}`}>
                {process.env.REACT_APP_TELL_HELP}
              </a>
            </Box>
          </Box>
        </Box>
      )}
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
