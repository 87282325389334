import { LoadingButton } from "@mui/lab";
interface Props  {
    text: string
    isLoading?: boolean
    type? : "submit" | "reset" | "button" | undefined,
    disabled?: boolean
}
const ManualButton: React.FC<Props> = (props) => {
    return <LoadingButton
        disabled={props.disabled}
        type={props.type === null ? "button" : props.type}
        className={"manual-button"}
        loadingPosition="start"
        startIcon={<span></span>}
        loading={props.isLoading}>
        {props.text}
    </LoadingButton>
}

export default ManualButton;
