import '../src/assets/styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import {
  RouterProvider,
} from "react-router-dom";
import router from "./routes";
import { ThemeProvider, createTheme } from '@mui/material';

function App() {
  const theme = createTheme({
    components: {
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       background: "red",
           
      //     },
      //   },
      // },
    },
  });
  return (
      <React.StrictMode>
         <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
         </ThemeProvider>
      
      </React.StrictMode>
  );
}

export default App;
