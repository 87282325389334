import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const roleService = {
    getList: async () => {
        return await apiInstance.get(endPointAPI.ROLE.GET);
    }, 
    createRole: async (payload: {
        name: string
    }) => {
        return await apiInstance.post(endPointAPI.ROLE.CREATE, payload);
    },
    updateRole: async (payload: {
        name: string, 
        id: number
    }) => {
        return await apiInstance.put(endPointAPI.ROLE.UPDATE , payload);
    },
    deleteRole: async (payload: {
        id: number
    }) => {
        return await apiInstance.delete(endPointAPI.ROLE.DELETE + `/${payload.id}`);
    }
}

export default roleService;
