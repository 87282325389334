import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import LoadingPage from "../../components/Loading";
import ContentLayout from "../../components/layouts/ContentLayout";
import {
  Permission,
  Role,
  RoleToPermission,
} from "../../interfaces/roleAndPermissionInterface";
import permissionService from "../../services/permissionService";
import roleService from "../../services/roleService";
import CreateRoleModal from "../roles/CreateRoleModal";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

const RolePage = () => {
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleID, setSelectedRoleID] = useState(0);
  const [listPermissions, setListPermissions] = useState<any[]>([]);
  const [rolePermissions, setRolePermissions] = useState<RoleToPermission[]>(
    []
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [roles, setRoles] = useState<Role[]>([]);
  const getListPermissions = async () => {
    setLoading(true);
    try {
      const response = await permissionService.getList();
      const permissions: Permission[] = response.data;
      setPermissions(permissions);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false);
  };
  const getListRoles = async () => {
    setLoading(true);
    try {
      const response = await roleService.getList();
      const roles: Role[] = response.data;
      setRoles(roles);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    getListRoles();
    getListPermissions();
  }, []);
  useEffect(() => {
    if (permissions.length > 0) {
      const data = permissions.map((permission) => ({
        permissionId: permission.id,
        name: permission.name,
        hasView: false,
        hasUpdate: false,
        hasDelete: false,
        hasCreate: false,
      }));
      setListPermissions(data);
      setRolePermissions(data);
    }
  }, [permissions]);

  const clearFormCreate = () => {
    setOpenDialogCreate(false);
    setViewMode(false);
    setSelectedRoleID(0);
    setRoleName("");
    const data = permissions.map((permission) => ({
      permissionId: permission.id,
      name: permission.name,
      hasView: false,
      hasUpdate: false,
      hasDelete: false,
      hasCreate: false,
    }));
    setListPermissions(data);
    setRolePermissions(data);
  };
  const handleCreateRole = async (event: any) => {
    event.preventDefault();
    const payload = {
      name: roleName,
      permissions: rolePermissions,
    };
    if (roleName?.length > 0) {
      try {
        const response = await roleService.createRole(payload);
        Swal.fire("", response?.data?.message, "success");
        getListRoles();
      } catch (e: any) {
        Swal.fire("", e?.data?.message, "error");
      }
      clearFormCreate();
    }
  };

  const listData = useMemo(() => {
    //Current Page slice
    let baseData = roles;

    if (filterText)
      baseData = baseData.filter((e: any) =>
        e?.name?.toLowerCase().trim().includes(filterText?.toLowerCase().trim())
      );

    return baseData?.slice(
      (page - 1) * perPage,
      (page - 1) * perPage + perPage
    );
  }, [roles, page, perPage, filterText]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: `calc(100vw - 360px)`,
            overflow: "hidden",
            m: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "82vh",
            background: "#f5f5f5",
          }}
        >
          <LoadingPage />
        </Box>
      ) : (
        <ContentLayout
          heading="Role Page"
          extraActions={
            <Button
              variant={"contained"}
              size="medium"
              onClick={() => {
                clearFormCreate();
                setOpenDialogCreate(true);
              }}
              sx={{ textTransform: "capitalize" }}
              className="bg-color-main"
              endIcon={<Add />}
            >
              Add Role
            </Button>
          }
          data={roles}
          getListTable={getListRoles}
          tableHeading={<TableHeading />}
          tableContent={listData.map((e: any, i: number) => (
            <TableItem
              key={i}
              data={e}
              dataKey={i}
              rolePermissions={rolePermissions}
              setRolePermissions={setRolePermissions}
              listPermissions={listPermissions}
              childrenCallback={getListRoles}
            />
          ))}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          filterText={filterText}
          setFilterText={setFilterText}
        />
      )}
      {openDialogCreate && (
        <Dialog open={openDialogCreate} fullWidth={true} maxWidth={"md"}>
          <DialogTitle>Create Roles</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please make sure to only create roles related to system
              functionality.
            </DialogContentText>

            <form autoComplete={"off"}>
              <TextField
                disabled={viewMode}
                autoComplete={"off"}
                required={true}
                error={roleName?.length > 0 ? false : true}
                helperText={roleName?.length > 0 ? null : "Enter role name"}
                autoFocus
                margin="dense"
                id={"outlined-error-helper-text"}
                label="Name"
                type="text"
                fullWidth
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />

              <CreateRoleModal
                listPermissions={listPermissions}
                rolePermissions={rolePermissions}
                setRolePermissions={setRolePermissions}
                viewMode={viewMode}
              />
              <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button className={"button-gray"} onClick={clearFormCreate}>
                  Cancel
                </Button>

                <Button
                  className={"manual-button"}
                  disabled={viewMode}
                  type={"submit"}
                  onClick={(e) => {
                    handleCreateRole(e);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RolePage;
