import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Permission, RoleToPermission } from '../../interfaces/roleAndPermissionInterface'
import CreateRoleModal from './CreateRoleModal'

interface Props {
    listPermissions: any[]
    showDetail: boolean
    setShowDetail: (data: boolean) => void
    data: Permission
    rolePermissions: RoleToPermission[];
  setRolePermissions: (data: RoleToPermission[]) => void;
}
const ModalView = ({showDetail, setShowDetail, data, listPermissions, rolePermissions, setRolePermissions} : Props) => {


  return (
    <Dialog open={showDetail} fullWidth={true} maxWidth={"md"}>
    <DialogTitle>View Roles</DialogTitle>
    <DialogContent>
      <form autoComplete={"off"}>
        <TextField
          autoComplete={"off"}
          required={true}
          error={data.name?.length > 0 ? false : true}
          helperText={
            data.name?.length > 0 ? null : "Enter permission name"
          }
          inputProps={{ readOnly: true, className: "bg-datepicker-readonly" }}
          margin="dense"
          id={"outlined-error-helper-text"}
          label="Name"
          type="text"
          fullWidth
          defaultValue={data.name}
          
        />
         <CreateRoleModal
              listPermissions={listPermissions}
              rolePermissions={rolePermissions}
              setRolePermissions={setRolePermissions}
              viewMode={true}
            />
        <DialogActions style={{ justifyContent: "flex-end" }}>
       
          <LoadingButton
            onClick={() => setShowDetail(!showDetail)}
            variant="contained"
            sx={{
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            className={"button-gray"}
            type={"submit"}
          >
            Cancel
          </LoadingButton>
      
       
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
  )
}

export default ModalView