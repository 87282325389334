import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
} from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { ChangeEvent, useState } from "react";
import Swal from "sweetalert2";
import ShowHistory from "../../components/modal/ShowHistory";
import { PlatformUserIndex } from "../../interfaces/platformUser";
import {
  Role,
  RoleToPermission,
} from "../../interfaces/roleAndPermissionInterface";
import platformUserService from "../../services/platformUserService";
import EditUserModal from "./EditUserModal";

const options = ["View", "Delete", "View History"];

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));
interface Props {
  data: any;
  dataKey: number;
  childrenCallback: () => void;
  roles: Role[];
  listPermissions: any[];
  rolePermissions: RoleToPermission[];
  setRolePermissions: (data: RoleToPermission[]) => void;
}
const TableItem = ({
  data,
  dataKey,
  childrenCallback,
  listPermissions,
  rolePermissions,
  setRolePermissions,
  roles,
}: Props) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [isEditButton, setIsEditButton] = useState(false);

  const [openDialogShowHistory, setOpenDialogShowHistory] = useState(false);
  const handleDelete = (id: number) => {
    Swal.fire({
      icon: "question",
      title: "Warning!",
      text: "Delete this? You can't revert this.",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "red",
    }).then(async (res) => {
      if (res?.isConfirmed) {
        setDisabledBtn(true);
        const payload = {
          id: id,
        };
        try {
          const response = await platformUserService.deletePlatformUser(
            payload
          );
          Swal.fire("", response?.data?.message, "success");
          childrenCallback();
        } catch (e: any) {
          Swal.fire("", e?.response?.data?.message, "error");
        }
        setDisabledBtn(false);
      }
    });
  };

const editUser = () => {
  setIsEditButton(true)
  setIsEdit(true)
}

  const handleClick = () => {
    switch (options[selectedIndex]) {
      case "View":
        return editUser();
      case "Delete":
        return handleDelete(data?.id);
      case "View History":
        return setOpenDialogShowHistory(true);
      default:
        return;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    switch (options[index]) {
      case "View":
        return editUser();
      case "Delete":
        return handleDelete(data?.id);
      case "View History":
        return setOpenDialogShowHistory(true);
      default:
        return;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  const handleChangeStatus = async (
    event: ChangeEvent<HTMLInputElement>,
    record: PlatformUserIndex,
    status: boolean
  ) => {
    event.preventDefault();
    Swal.fire({
      title: `Do you want ${status ? "deactivate" : "active"} user?`,
      showDenyButton: true,
      confirmButtonText: status ? "Deactivate" : "Active",
      denyButtonText: "Close",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          const response = await platformUserService.updateStatus(record.id);
          childrenCallback();
          Swal.fire("", response?.data?.message, "success");
          // getListRoles();
        } catch (e: any) {
          Swal.fire({
            icon: "error",
            width: 700,
            text: e?.response?.data?.message,
            showCloseButton: true,
          });
        }
      }
    });
  };

  const renderRole = (data: PlatformUserIndex) => {
    const UserRole = roles.find((item: any) => item.id === data.roleId);

    return <Chip label={UserRole?.name} variant="outlined" size="small" />;
  };
  return (
    <TableRow key={data?.id} className="item-hover">
      <StyledTableCell align="left">{dataKey + 1}</StyledTableCell>
      <StyledTableCell align="left">{`${data.firstName ? data.firstName : ""} ${
        data.middleName ? data.middleName : ""
      } ${data.lastName ? data.lastName : ""}`}</StyledTableCell>{" "}
      <StyledTableCell align="left">{data.email}</StyledTableCell>
      <StyledTableCell align="center">
        {moment(data.createdAt).format("DD-MMM-YYYY")}
      </StyledTableCell>
      <StyledTableCell align="center">{renderRole(data)}</StyledTableCell>
      <StyledTableCell align="center">
        <Switch
          checked={data.status}
          onChange={(e) => handleChangeStatus(e, data, data.status)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </StyledTableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            color={options[selectedIndex] === "Delete" ? "error" : "inherit"}
            sx={{ boxShadow: "unset", borderRadius: "20px" }}
          >
            <Button
              disabled={disabledBtn}
              onClick={handleClick}
              sx={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
            >
              {options[selectedIndex]}
            </Button>
            <Button
              disabled={disabledBtn}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
          {isEdit && (
             <EditUserModal setOpenEditUser={setIsEdit} openEditUser={isEdit} roles={roles} isEditButton={isEditButton} selectedUser={data} getListUser={childrenCallback} />
          )}

          {openDialogShowHistory && (
            <ShowHistory
              title={"User Platform History"}
              openDialogShowHistory={openDialogShowHistory}
              setOpenDialogShowHistory={setOpenDialogShowHistory}
              data={data.userHistoryEvents}
              type={"user"}
              listRole={roles}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
