import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { requestPlatformUser, PlatformUserEdit } from "../interfaces/platformUser";

const platformUserService = {
    getList: async () => {
        return await apiInstance.get(endPointAPI.PLATFORM_USER.GET);
    }, 
    createPlatformUser: async (payload: requestPlatformUser) => {
        return await apiInstance.post(endPointAPI.PLATFORM_USER.CREATE, payload);
    },
    updatePlatformUser: async (payload: PlatformUserEdit , id: number) => {
        return await apiInstance.put(`${endPointAPI.PLATFORM_USER.UPDATE}/${id}` , payload);
    },
    updateStatus: async (id: number) => {
        return await apiInstance.put(endPointAPI.PLATFORM_USER.UPDATE_STATUS + `/${id}`);
    },
    deletePlatformUser: async (payload: {
        id: number
    }) => {
        return await apiInstance.delete(endPointAPI.PLATFORM_USER.DELETE + `/${payload.id}`);
    }
}

export default platformUserService;
