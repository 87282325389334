import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useState } from "react";
import EditUserModal from "../company/EditCompanyModal";

const options = ["View"];

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));
interface Props {
  data: any;
  dataKey: number;
  childrenCallback: () => void;
}
const TableItem = ({
  data,
  dataKey,
  childrenCallback,
}: Props) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [isEditButton, setIsEditButton] = useState(false);

  const [openDialogShowHistory, setOpenDialogShowHistory] = useState(false);
const editUser = () => {
  setIsEditButton(true)
  setIsEdit(true)
}

  const handleClick = () => {
    switch (options[selectedIndex]) {
      case "View":
        return editUser();
      case "View History":
        return setOpenDialogShowHistory(true);
      default:
        return;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    switch (options[index]) {
      case "View":
        return editUser();
      case "View History":
        return setOpenDialogShowHistory(true);
      default:
        return;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };


  return (
    <TableRow key={data?.id} className="item-hover">
      <StyledTableCell align="left">{dataKey + 1}</StyledTableCell>
      <StyledTableCell align="left">{data.code}</StyledTableCell>{" "}
      <StyledTableCell align="left">{data.name}</StyledTableCell>
      <StyledTableCell align="left">{data.mainEmail}</StyledTableCell>
      <StyledTableCell align="left">{data.solution ? data.solution : "Unset"}</StyledTableCell>
      <StyledTableCell align="left">{data.plan ? data.plan : "Unset"}</StyledTableCell>
      <StyledTableCell align="center">
        {moment(data.createdAt).format("DD-MMM-YYYY")}
      </StyledTableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            color={options[selectedIndex] === "Delete" ? "error" : "inherit"}
            sx={{ boxShadow: "unset", borderRadius: "20px" }}
          >
            <Button
              disabled={disabledBtn}
              onClick={handleClick}
              sx={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
            >
              {options[selectedIndex]}
            </Button>
            <Button
              disabled={disabledBtn}
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
          {isEdit && (
             <EditUserModal setOpenEditUser={setIsEdit} openEditUser={isEdit} isEditButton={isEditButton} selectedCompany={data} getListCompany={childrenCallback} />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
