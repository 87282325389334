import { Create, NoteAlt } from "@mui/icons-material";
import {
  TabContext,
  TabList,
  TabPanel,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Tab,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import Swal from "sweetalert2";
import { Notes } from "../../../interfaces/companyCRM";
import { ContactIndex } from "../../../interfaces/contactCRM";
import { History } from "../../../interfaces/roleAndPermissionInterface";
import contactService from "../../../services/contactService";
import { convertUTCtoLocalTime } from "../../../utils";

interface Props {
  selectedContact: ContactIndex | undefined;
  isEdit: boolean;
}
const NotesComponent = ({ selectedContact, isEdit }: Props) => {
  const [value, setValue] = React.useState("notes");
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [txtComment, setTxtComment] = React.useState("");
  const [arrComment, setArrComment] = React.useState<Notes[]>([]);
  const [page, setPage] = React.useState(1);
  const [logCompany, setLogCompany] = React.useState<History[]>([]);
  const [errNote, setErrNote] = React.useState(false);
  const [txtErrNote, setTxtErrNote] = React.useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  async function fetchLogCompany(id: number) {
    try {
      let response = await contactService.getHistory({ id: id });
      if (response?.data) setLogCompany(response?.data);
    } catch (err) {
      console.log(err);
    }
  }
  const showComment = async (id: number) => {
    try {
      const response = await contactService.getNotes({ id: id });
      if (response?.data) {
        setArrComment(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (selectedContact) {
      fetchLogCompany(selectedContact.id);
      showComment(selectedContact.id);
    }
  }, [selectedContact]);

  const saveNote = async (e: any) => {
    if (txtComment === "") {
      setErrNote(true);
      setTxtErrNote("Please enter the note");
      return false;
    }
    e.preventDefault();
    if (selectedContact) {
      try {
        const payload = {
          content: txtComment,
        };
        setDisabledBtn(true);
        const response = await contactService.storeNote(
          payload,
          selectedContact.id
        );
        if (response?.data) {
          setDisabledBtn(false);
          setTxtComment("");
          showComment(selectedContact.id);
          Swal.fire("", response?.data?.message, "success");
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          width: 700,
          text: e?.response?.data?.message,
          showCloseButton: true,
        });
      }
      setDisabledBtn(false);
    }
  };
  const handleChangePagiNote = (event: any, value: number) => {
    setPage(value);
    fetchLogCompany(value);
  };
  function ContentLog() {
    return (
      <>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {(logCompany ?? []).map((item: History, index: number) => {
            const events = JSON.parse(item.events);
            return (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="info">
                    <Create />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, fontSize: "0.75rem" }}
                    >
                      {item.userCreate.firstName} {item.userCreate.lastName}
                    </Typography>
                    <Chip
                      label={convertUTCtoLocalTime(
                        item.createdAt,
                        "DD-MMM-YYYY, H:m A"
                      )}
                      variant="outlined"
                      size="small"
                    />
                  </Stack>
                  <Paper
                    elevation={1}
                    sx={{
                      p: 1,
                      mt: 1,
                      borderRadius: "0.75rem",
                      background: "#f0f2fc",
                    }}
                  >
                    <Box>
                      {events.map((itemNote: any, index: number) => (
                        <Typography
                          sx={{
                            fontSize: "0.75rem",
                            color: "#6c757d",
                            fontWeight: 300,
                          }}
                          key={index}
                        >
                          {`Change `}
                          {`${itemNote.field}`} {" from "}{" "}
                          <span
                            style={{ color: "red" }}
                          >{`\'${itemNote.oldValue}\'`}</span>{" "}
                          {" to "}{" "}
                          <span
                            style={{ color: "blue" }}
                          >{`\'${itemNote.newValue}\'`}</span>
                        </Typography>
                      ))}
                    </Box>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          m={3}
          pb={3}
        >
          {/* <Pagination
            count={logCompany.last_page}
            page={page}
            onChange={handleChangePagiNote}
          /> */}
        </Stack>
      </>
    );
  }
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            sx={{
              "& .Mui-selected": {
                color: "#fff !important",
                backgroundColor: "#0094d3",
              },
            }}
          >
            <Tab
              sx={{ flex: 1, fontSize: "0.75rem" }}
              label="Notes"
              value="notes"
            />
            <Tab
              sx={{ flex: 1, fontSize: "0.75rem" }}
              label="Activity"
              value="activity"
            />
          </TabList>
        </Box>
        <TabPanel
          value="notes"
          sx={{ fontSize: "1rem", padding: "1rem 0.5rem" }}
        >
          <TextField
            id="outlined-multiline-static"
            label="Note"
            placeholder="Note here"
            value={txtComment}
            onChange={(e) => setTxtComment(e.target.value)}
            error={errNote}
            helperText={txtErrNote}
            multiline
            fullWidth
            inputProps={{ readOnly: !isEdit }}
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="outlined"
            color="info"
            sx={{
              mt: 2,
              textTransform: "capitalize",
              width: "50%",
              maxWidth: "150px",
              borderRadius: "8px",
            }}
            size="small"
            onClick={saveNote}
            disabled={disabledBtn || !isEdit}
          >
            Send Note
          </Button>
          <Timeline
            sx={{
              p: 0,
              pt: 4,
              "& .MuiTimelineItem-root:before": { content: "unset" },
            }}
          >
            {arrComment
              ? arrComment.map((commentDetail: Notes, index: number) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot color="info">
                        <NoteAlt />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "0.75rem", fontWeight: 500 }}
                        >
                          {commentDetail.userCreate.firstName}{" "}
                          {commentDetail.userCreate.lastName}
                        </Typography>
                        <Chip
                          sx={{ fontSize: "0.75rem" }}
                          label={convertUTCtoLocalTime(
                            commentDetail.createdAt,
                            "DD-MMM-YYYY, H:m A"
                          )}
                          variant="outlined"
                          size="small"
                        />
                      </Stack>
                      <Paper
                        elevation={1}
                        sx={{
                          p: 1,
                          mt: 1,
                          borderRadius: "0.75rem",
                          background: "#f0f2fc",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "0.75rem" }}
                        >
                          {commentDetail.content}
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))
              : ""}
          </Timeline>
        </TabPanel>
        <TabPanel
          value="activity"
          sx={{ fontSize: "1rem", padding: "1rem 0.5rem" }}
        >
          <Paper
            sx={{
              p: 0,
              mt: 2,
              borderRadius: "0.75rem",
              boxShadow: "unset",
            }}
          >
            {3 > 0 ? (
              <ContentLog />
            ) : (
              <Typography variant="body2" align="center">
                No Activities
              </Typography>
            )}
          </Paper>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default NotesComponent;
