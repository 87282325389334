import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { requestCompany, requestEditCompany } from "../interfaces/companyCRM";
import {
  requestPlatformUser,
  PlatformUserEdit,
} from "../interfaces/platformUser";

const companyService = {
  getList: async () => {
    return await apiInstance.get(endPointAPI.COMPANY_CRM.GET_LIST);
  },
  getDetail: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_DETAIL + `/${payload.id}`
    );
  },
  createCompany: async (payload: requestCompany) => {
    return await apiInstance.post(endPointAPI.COMPANY_CRM.CREATE, payload);
  },
  updateCompany: async (payload: requestEditCompany, id: number) => {
    return await apiInstance.put(
      `${endPointAPI.COMPANY_CRM.UPDATE}/${id}`,
      payload
    );
  },
  deleteCompany: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.COMPANY_CRM.DELETE + `/${payload.id}`
    );
  },
  //  End Company
  storeNote: async (payload: { content: string }, company_id: number) => {
    return await apiInstance.post(
      `${endPointAPI.COMPANY_CRM.STORE_NOTE}/${company_id}`,
      payload
    );
  },
  sendEmail: async (
    payload: { subject: string; content: string },
    company_id: number
  ) => {
    return await apiInstance.post(
      `${endPointAPI.COMPANY_CRM.SEND_EMAIL}/${company_id}`,
      payload
    );
  },
  getNotes: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_NOTES + `/${payload.id}`
    );
  },
  getHistory: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_HISTORY + `/${payload.id}`
    );
  },
  getEmailSent: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_EMAIL_SENT + `/${payload.id}`
    );
  },

  uploadDocument: async (payload: { name: string, path: string }, company_id: number) => {
    return await apiInstance.post(
      `${endPointAPI.COMPANY_CRM.UPLOAD_DOCS}/${company_id}`,
      payload
    );
  },
  getListDocs: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.COMPANY_CRM.GET_LIST_DOCUMENTS + `/${payload.id}`
    );
  },
  deleteDocs: async (payload: { id: number }) => {
    return await apiInstance.delete(
      endPointAPI.COMPANY_CRM.DELETE_DOCS + `/${payload.id}`
    );
  },

  getContactCompany: async (payload: { id: number }) => {
    return await apiInstance.get(
      endPointAPI.CONTACT_CRM.CONTACT_COMPANY + `/${payload.id}`
    );
  },
};

export default companyService;
