import axios from "axios";

const httpInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 9000,
    headers: {
        "Content-Type": "application/json"
    }
});

httpInstance.interceptors.request.use(function (config){
    const token = localStorage.getItem("accessToken");
    config.headers["Content-Type"] = "application/json"
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

httpInstance.interceptors.response.use(function (response){
    if (response.status === 200) return response;
    console.log(response);
    return response;
})

export default httpInstance;
