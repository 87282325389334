import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Swal from "sweetalert2";
import sampleAvatar from "../../assets/images/user.png";
import imageService from "../../services/imageService";
import LoadingPage from "../Loading";
interface AvatarUploadInterface {
  listImages: string[];
  setListImages: any;
  page: string;
  widthImg: number | string;
  heightImg: number | string;
  idBtnUpload: string;
  classes: string;
  isEdit?: boolean
}
const AvatarUploader = ({
  listImages,
  setListImages,
  page,
  widthImg,
  heightImg,
  idBtnUpload,
  classes,
  isEdit=true
}: AvatarUploadInterface) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    const reader: any = new FileReader();

    if (!file) {
      Swal.fire({
        icon: "error",
        text: "Please select an image.",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }

    if (!file.type.startsWith("image/")) {
      Swal.fire({
        icon: "error",
        text: "Only image files are allowed.",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }

    if (
      file.size / 1024 >
      Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE)
    ) {
      Swal.fire({
        icon: "error",
        text:
          "Max file size is " +
          Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE) / 1024 +
          "MB!",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }

    reader.onload = async () => {
      setLoading(true);
      const res = await imageService
        .uploadImage(
          file,
          page,
          widthImg,
          heightImg,
          process.env.REACT_APP_BUCKET_S3,
          process.env.REACT_APP_BUCKET_FOLDER,
          process.env.REACT_APP_COMPANY_NAME
        )
        .then((res: any) => {
          if (res?.status === 200) {
            return res?.data?.response;
          }
          return;
        });

      if (res) {
        setListImages([
          res?.path.replace(`/${process.env.REACT_APP_BUCKET_FOLDER}`, ""),
        ]);
        setSuccess(true);
        setError("");
        Swal.fire({
          icon: "success",
          text: "Upload Image Success!",
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
        });
      } else {
        setSuccess(false);
        setError("Upload fail!");
        Swal.fire({
          icon: "error",
          text: "Upload Image Fail!",
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
        });
      }
      setLoading(false);
    };

    reader.readAsDataURL(file);
  };
  const VisuallyHiddenInput = styled("input")({
    height: "100%",
    position: "absolute",
    width: "100%",
    cursor: "pointer",
    top: 0,
    right: 0,
    zIndex: 99,
    fontSize: "13px",
    opacity: 0,
  });

  
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
       <Stack sx={{ position: "relative", width: "100%", maxHeight: "160px", maxWidth: "160px",
    aspectRatio: "1/1", color: "#5b5b5b", bgcolor: "#f5f5f5", borderRadius: "50%", overflow: "hidden", mx: 3, p: 1 }} alignItems={"center"} justifyContent={"center"}>
      {loading ? (
        <LoadingPage />
      ) : listImages?.length > 0 ? (
        listImages.map((item: any, i: number) => {
          return (
            <Box className="col-auto" key={i}>
              <Box>
                <img
                  width={300}
                  height={300}
                  src={`${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${item}`}
                  alt="avatar"
                  className="img-ratio rounded-4"
                  style={{ objectFit: "cover", overflow: "hidden", position: "absolute", top: 0, left: 0 }}
                />
              </Box>
            </Box>
          );
        })
      ) : (
        <Box>
          <img
            width={65}
            height={65}
            src={sampleAvatar}
            alt="img"
            className="img-ratio rounded-4"
            style={{ objectFit: "contain" }}
          />
        </Box>
      )}

      <Box className="col-auto" style={{ paddingLeft: "10px" }}>
        <Box
          mt={0}
          sx={{
            color: "#000",
            height: "100%",
          }}
        >
          <label
            htmlFor={idBtnUpload}
            role="button"
            className=""
          >
            {loading ? (
              <></>
            ) : (
                <span style={{margin: "0 auto"}}>Browse or<br />Drag & Drop</span>
            )}
          </label>
          <VisuallyHiddenInput
            type="file"
            id={idBtnUpload}
            accept="image/png, image/jpeg"
            onChange={handleImageChange}
            className="file-input"
            disabled={!isEdit}
          />
        </Box>
     
      </Box>   
    </Stack>{error && !success && <Box sx={{color: "red", mt: 1}}>{error}</Box>}
    </Box>
   
  );
};

export default AvatarUploader;
