import TableCell from '@mui/material/TableCell';
import TableHeader from '../../components/layouts/TableHeader';

const TableHeading = () => {
  return (
    <TableHeader>
      <TableCell sx={{bgcolor: "#f4f7fe"}} align="left">#</TableCell>
      <TableCell sx={{bgcolor: "#f4f7fe"}} align="left">Name</TableCell>
      <TableCell sx={{bgcolor: "#f4f7fe"}} align="center">Created At</TableCell>
      <TableCell sx={{bgcolor: "#f4f7fe"}} align="center">Updated At</TableCell>
      <TableCell sx={{bgcolor: "#f4f7fe"}} align="center">Updated By</TableCell>
      <TableCell sx={{bgcolor: "#f4f7fe"}} align="center">Actions</TableCell>
    </TableHeader>
  );
};

export default TableHeading;
