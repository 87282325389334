import { Close } from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  Box,
  Container,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Stack
} from "@mui/material";
import { History } from "../../interfaces/roleAndPermissionInterface";
import { convertUTCtoLocalTime } from "../../utils";

interface Props {
  openDialogShowHistory: boolean;
  setOpenDialogShowHistory: (data: boolean) => void;
  data: History[];
}
const ShowHistory = ({
  openDialogShowHistory,
  setOpenDialogShowHistory,
  data,
}: Props) => {

  const renderPermission = (data: {
    hasView: boolean
    hasUpdate: boolean
    hasDelete: boolean
    hasCreate: boolean
  }) => {
    const resultDisable = [];
    const resultEnable = [];
    for (const [key, value] of Object.entries(data)) {
      if(!value) {
        resultDisable.push(key);
      } else {
        resultEnable.push(key);
      }
    }
    return ({enable: resultEnable.join(", "), disable: resultDisable.join(", ")})
  }
  return (
    <Drawer
      anchor="right"
      open={openDialogShowHistory}
      onClose={() => setOpenDialogShowHistory(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
        }}
      >
        Role History
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialogShowHistory(false)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{p: 0}}>
      {data.length > 0 ? <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.35,
        }, width:  `calc(100vw - 310px)`, m: 2  }}>
          {data.map((item, index) => {
            const events = JSON.parse(item.events ?? "");
            return (
              <TimelineItem key={index}>
                <TimelineOppositeContent color="text.secondary">
                  <Box>
                    <p style={{color: "black"}}>{convertUTCtoLocalTime(item.createdAt)}</p>
                    <p>
                      Action by{" "}
                      <span
                        style={{ fontWeight: 600, color: "black" }}
                      >{`${item.userCreate.firstName} ${item.userCreate.lastName}`}</span>
                    </p>
                  </Box>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <>
                    {Object.keys(events.oldValue || {}).map((element, ind) => {
                      if (
                        events.oldValue[element] !== events.newValue[element]
                      ) {

                        if(element === "name") {
                          return (
                          <Container
                            maxWidth="xl"
                            sx={{ borderBottom: "1px solid #f5f5f5", m: 0, p: 0 }}
                            key={ind}
                          >
                            <Stack
                              spacing={{ xs: 0, sm: 1 }}
                              direction="row"
                              useFlexGap
                              flexWrap="wrap"
                            >
                              <div style={{ fontWeight: 600 }}>Change</div>
                              <div
                                style={{ color: "#0094d3" }}
                              >{`\"${element}\"`}</div>
                              <div style={{ fontWeight: 600 }}>from:</div>{" "}
                              <div style={{ color: "black" }}>
                                <i>{`\"${events.oldValue[element]}\"`}</i>
                              </div>
                              <div style={{ fontWeight: 600 }}>to:</div>
                              <div style={{ color: "#008000" }}>
                              <i>{`\"${events.newValue[element]}\"`}</i>
                              </div>
                            </Stack>
                          </Container>
                        );
                        } else {

                          return (
                            <Container
                              maxWidth="xl"
                              sx={{ borderBottom: "1px solid #f5f5f5", m: 0, p: 0 }}
                              key={ind}
                            >
                              <Stack
                                spacing={{ xs: 0, sm: 1 }}
                                direction="row"
                                useFlexGap
                                flexWrap="wrap"
                              >
                               <div style={{ fontWeight: 600 }}>Enable:</div>
                                <div style={{ color: "#008000" }}>
                                <i>{`\"${renderPermission(events.newValue[element]).enable}\"`}</i>
                                </div>
                                {" , "}<div style={{ fontWeight: 600 }}>disable:</div>
                                <div style={{ color: "black" }}>
                                  <i>{`\"${renderPermission(events.newValue[element]).disable}\"`}</i>
                                </div>
                                <div style={{ fontWeight: 600 }}>for</div>
                                <div
                                  style={{ color: "#0094d3" }}
                                >{`\"${element}\"`}</div>
                              </Stack>
                            </Container>
                          );
                        }

                      }
                    })}
                  </>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline> : <Box sx={{width: `calc(100vw - 310px)`, p: 2, fontSize: "18px"}}>No history to show</Box>}
      </DialogContent>
    </Drawer>
  );
};

export default ShowHistory;
