import { Delete } from "@mui/icons-material";
import {
  Button,
  Fade,
  FormControlLabel,
  List,
  Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import DocumentUploader from "../../../components/DocumentUpload";
import { Document, DocumentIndex } from "../../../interfaces/common";
import { CompanyIndex } from "../../../interfaces/companyCRM";
import companyService from "../../../services/companyService";

interface Props {
  selectedCompany: CompanyIndex | undefined;
  isEdit: boolean;
}
export default function ContactComponent({ selectedCompany, isEdit }: Props) {
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [listDocuments, setListDocuments] = React.useState<DocumentIndex[]>([]);
  const [viewDocuments, setViewDocuments] = React.useState(false);

  const handleChange = () => {
    setViewDocuments((prev) => !prev);
  };
    const getDocuments = async (id: number) => {
         try {
          const response = await companyService.getListDocs({id: id});
          if (response.data) {
            setListDocuments(response.data);
          }
      } catch (error) {
          console.log(error);
      }

  }

  React.useEffect(() => {
    if (selectedCompany) {
      getDocuments(selectedCompany.id)
    }
  }, [selectedCompany]);

  const handleUploadDocs = async (documents: Document[]) => {
    if (selectedCompany && documents.length > 0) {
      try {
        const payload = {
          name: documents[0].name,
          path: documents[0].path,
        };
        setDisabledBtn(true);
        const response = await companyService.uploadDocument(
          payload,
          selectedCompany?.id
        );
        if (response?.data) {
          getDocuments(selectedCompany.id)

          Swal.fire("", response?.data?.message, "success");
        }
      } catch (e: any) {
        Swal.fire({
          icon: "error",
          width: 700,
          text: e?.response?.data?.message,
          showCloseButton: true,
        });
      }
      setDisabledBtn(false);
    }
  };

    const handleDeleteDocument = async (id: number) => {      
      Swal.fire({
        icon: "info",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#cd1322",
        confirmButtonText: "Yes, delete it",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const payload = {
            id: id,
          };
          try {
            const response = await companyService.deleteDocs(payload);
            Swal.fire("", response?.data?.message, "success");
            if(selectedCompany) {
              getDocuments(selectedCompany.id)
            }
          } catch (e: any) {
            Swal.fire("", e?.response?.data?.message, "error");
          }
        }
      });
    };
  React.useEffect(() => {
    if (documents.length > 0) {
      handleUploadDocs(documents);
    }
  }, [documents]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <DocumentUploader
          documents={documents}
          setDocuments={setDocuments}
          page={"crm-company"}
          widthImg={""}
          heightImg={""}
          idBtnUpload={"company-document"}
          isEdit={isEdit}
        />

        <FormControlLabel
          control={
          <Button sx={{ fontSize: "0.75rem" }} onClick={handleChange}>
              View Documents
            </Button>
       
            
          }
          label=""
        />
      </Stack>
      {(listDocuments.length > 0 && viewDocuments) && <Box sx={{ display: "flex", width: "100%" }}>
        <Fade in={viewDocuments}>
          <List sx={{width: "100%"}}>
            {listDocuments.map((item: DocumentIndex, index: number) => (
              <Stack
                key={index}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <a  href={`${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${item.path}`}>{item.name}</a>
                  <Button onClick={() => handleDeleteDocument(item.id)}>
                    <Delete color="error" />
                  </Button>
                
              </Stack>
            ))}
          </List>
        </Fade>
      </Box>}
    </Box>
  );
}
