import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const solutionService = {
    getList: async () => {
        return await apiInstance.get(endPointAPI.SOLUTION.GET_LIST);
    }, 
}

export default solutionService;
