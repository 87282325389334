import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const userService = {
    getCurrent: async () => {
        return await apiInstance.get(endPointAPI.USER.CURRENT);
    }
}

export default userService;
