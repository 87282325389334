import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import {
  Permission,
  RoleToPermission,
} from "../../interfaces/roleAndPermissionInterface";
import roleService from "../../services/roleService";
import CreateRoleModal from "./CreateRoleModal";

interface Props {
  listPermissions: any[];
  isEdit: boolean;
  setIsEdit: (data: boolean) => void;
  data: Permission;
  parentCallback: () => void;
  rolePermissions: RoleToPermission[];
  setRolePermissions: (data: RoleToPermission[]) => void;
}
const ModalAction = ({
  isEdit,
  setIsEdit,
  data,
  parentCallback,
  listPermissions,
  rolePermissions,
  setRolePermissions,
}: Props) => {
  const [roleName, setRoleName] = useState(data.name);
  const [loading, setLoading] = useState(false);

  const handleEditRole = async (event: any) => {
    event.preventDefault();
    const payload = {
      id: data.id,
      name: roleName,
      permissions: rolePermissions,
    };

    if (roleName?.length > 0) {
      setLoading(true);
      try {
        const response = await roleService.updateRole(payload);
        Swal.fire("", response?.data?.message, "success");
        parentCallback();
      } catch (e: any) {
        Swal.fire("", e?.data?.message, "error");
      }
      setLoading(false);
    }
  };
  return (
    <Dialog open={isEdit} fullWidth={true} maxWidth={"md"}>
      <DialogTitle>Edit Permission</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please make sure to only edit permissions related to system
          functionality.
        </DialogContentText>

        <form autoComplete={"off"}>
          <TextField
            autoComplete={"off"}
            required={true}
            error={roleName?.length > 0 ? false : true}
            helperText={
              roleName?.length > 0 ? null : "Enter permission name"
            }
            autoFocus
            margin="dense"
            id={"outlined-error-helper-text"}
            label="Name"
            type="text"
            fullWidth
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
          />
          <CreateRoleModal
            listPermissions={listPermissions}
            rolePermissions={rolePermissions}
            setRolePermissions={setRolePermissions}
            viewMode={false}
          />
          <DialogActions style={{ justifyContent: "space-between" }}>
            <LoadingButton
              onClick={() => setIsEdit(!isEdit)}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                marginRight: "10px",
              }}
              className={"button-gray"}
              disabled={loading}
              type={"submit"}
            >
              Cancel
            </LoadingButton>

            {isEdit && (
              <LoadingButton
                onClick={handleEditRole}
                className={"manual-button"}
                disabled={loading}
                type={"submit"}
                startIcon={<span></span>}
                loadingPosition={"start"}
                loading={loading}
              >
                Save
              </LoadingButton>
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAction;
