import { TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import * as React from "react";
import {
  RolePermission,
  RoleToPermission
} from "../../interfaces/roleAndPermissionInterface";
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Permission Name",
  },
  {
    id: "view",
    numeric: true,
    disablePadding: false,
    label: "View",
  },
  {
    id: "create",
    numeric: true,
    disablePadding: false,
    label: "Create",
  },
  {
    id: "update",
    numeric: true,
    disablePadding: false,
    label: "Update",
  },
  {
    id: "delete",
    numeric: true,
    disablePadding: false,
    label: "Delete",
  },
];

interface Props {
  rolePermissions: RoleToPermission[];
  setRolePermissions: (data: RoleToPermission[]) => void;
  listPermissions: RolePermission[];
  viewMode: boolean
}

export default function CreateRoleModal({ listPermissions, rolePermissions, setRolePermissions, viewMode  }: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { checked } = event.target;
    const rows = [...rolePermissions];
    if (checked) {
      const selectAllData = rows.map((item) => ({
        ...item,
        permissionId: item.permissionId,
        hasView: true,
        hasUpdate: true,
        hasDelete: true,
        hasCreate: true,
      }));
      setRolePermissions(selectAllData);
    } else {
      const unSelectAllData = rows.map((item) => ({
        ...item,
        permissionId: item.permissionId,
        hasView: false,
        hasUpdate: false,
        hasDelete: false,
        hasCreate: false,
      }));
      setRolePermissions(unSelectAllData);
    }
  };

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    row: RolePermission
  ) => {
    const rows = [...rolePermissions];
    const selectedRow: boolean = event?.target?.checked;
    if (selectedRow) {
      rows[index] = {
        ...rows[index],
        permissionId: row.permissionId,
        hasView: true,
        hasUpdate: true,
        hasDelete: true,
        hasCreate: true,
      };
    } else {
      rows[index] = {
        ...rows[index],
        permissionId: row.permissionId,
        hasView: false,
        hasUpdate: false,
        hasDelete: false,
        hasCreate: false,
      };
    }
    setRolePermissions(rows);
  };

  const isSelected = (row: RoleToPermission) =>
    row.hasView && row.hasCreate && row.hasUpdate && row.hasDelete;
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const visibleRows = React.useMemo(
    () =>
      listPermissions.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, listPermissions]
  );
  const handleChangeComponents =
    (index: number, id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let { name, checked } = e.target;
      const rows = [...rolePermissions];

      rows[index] = {
        ...rows[index],
        permissionId: id,
        [name]: checked,
      };
      setRolePermissions(rows);
    };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                  disabled={viewMode}
                    color="primary"
                    checked={rolePermissions.every((item) => isSelected(item))}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    <TableSortLabel>{headCell.label}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {visibleRows.map((row, idx) => {
                // const isItemSelected = isSelected(row.permissionId);
                const index = idx + page * rowsPerPage;
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    // onClick={(event:any) => handleClick(event, index, row)}
                    role="checkbox"
                    // aria-checked={row.permissionId}
                    tabIndex={-1}
                    key={row.permissionId}
                    // selected={isSelected(rolePermissions[index])}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                      disabled={viewMode}
                        color="primary"
                        checked={isSelected(rolePermissions[index])}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleClick(event, index, row)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>

                    <TableCell align="right">
                      <Checkbox
                      disabled={viewMode}
                        color="primary"
                        name="hasView"
                        checked={rolePermissions[index].hasView}
                        onChange={handleChangeComponents(
                          index,
                          row.permissionId
                        )}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        disabled={viewMode}
                        name="hasCreate"
                        checked={rolePermissions[index].hasCreate}
                        onChange={handleChangeComponents(
                          index,
                          row.permissionId
                        )}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        disabled={viewMode}
                        name="hasUpdate"
                        checked={rolePermissions[index].hasUpdate}
                        onChange={handleChangeComponents(
                          index,
                          row.permissionId
                        )}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        name="hasDelete"
                        disabled={viewMode}
                        checked={rolePermissions[index].hasDelete}
                        onChange={handleChangeComponents(
                          index,
                          row.permissionId
                        )}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={listPermissions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
