import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { Permission } from "../interfaces/roleAndPermissionInterface";

const permissionService = {
    getList: async () => {
        return await apiInstance.get(endPointAPI.PERMISSION.GET);
    }, 
    createPermission: async (payload: {
        name: string
    }) => {
        return await apiInstance.post(endPointAPI.PERMISSION.CREATE, payload);
    },
    updatePermission: async (payload: {
        name: string, 
        id: number
    }) => {
        return await apiInstance.put(endPointAPI.PERMISSION.UPDATE , payload);
    },
    deletePermission: async (payload: {
        id: number
    }) => {
        return await apiInstance.delete(endPointAPI.PERMISSION.DELETE + `/${payload.id}`);
    }
}

export default permissionService;
