import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BadgeIcon from "@mui/icons-material/Badge";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { SvgIconProps } from "@mui/material/SvgIcon";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  bgColorForDarkMode?: string;
  color?: string;
  colorForDarkMode?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  active?: boolean;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    // [`& .${treeItemClasses.content}`]: {
    //   paddingLeft: theme.spacing(2),
    // },
  },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
  props: StyledTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    active,
    ...other
  } = props;

  //   #fff3
  const styleProps = {
    // "--tree-view-bg-color": active ? "#fff3" : "transparent",
    backgroundColor: active ? "#536270" : "",
    margin: "5px",
    borderRadius: "8px",
    marginLeft: "16px"
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            pr: 0,
          }}
        >
          <Box component={LabelIcon} color="white" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "300",
              flexGrow: 1,
              color: "white",
              fontFamily: "Montserrat",
            }}
          >
            {labelText}
          </Typography>
        </Box>
      }
      style={styleProps}
      {...other}
      ref={ref}
    />
  );
});

export default function TKGTreeView() {
  const location = useLocation();
  const navigate = useNavigate();
  const [defaultExpand, setDefaultExpand] = useState<any[]>([]);

  useEffect(() => {
    if (location.pathname.includes("setting")) setDefaultExpand(["6"]);
  }, []);

  useEffect(() => {
    console.log(defaultExpand);
  }, [defaultExpand]);

  const actionExpanded = () => {
    switch (location.pathname) {
      case "/setting/roles":
      case "/setting/permissions":
        return ["6"];
        break;
      case "/crm/company":
      case "/crm/contact":
        return ["3"];
        break;

      default:
        break;
    }
  };
  return (
    <TreeView
      aria-label="TKG"
      defaultCollapseIcon={<ExpandMoreIcon style={{ color: "white" }} />}
      defaultExpandIcon={<ChevronRightIcon style={{ color: "white" }} />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
      defaultExpanded={actionExpanded()}
    >
      <StyledTreeItem
        nodeId="1"
        onClick={() => navigate("/")}
        active={location.pathname === "/"}
        labelText="Dashboard"
        labelIcon={DashboardIcon}
      />
      <StyledTreeItem nodeId="3" labelText="CRM" labelIcon={ManageAccountsIcon}>
        <StyledTreeItem
          onClick={() => navigate("/crm/company")}
          active={location.pathname === "/crm/company"}
          nodeId="3_1"
          labelText="Company"
          labelIcon={ApartmentIcon}
          labelInfo="90"
          color="#1a73e8"
          bgColor="#e8f0fe"
          colorForDarkMode="#B8E7FB"
          bgColorForDarkMode="#071318"
        />
        <StyledTreeItem
          onClick={() => navigate("/crm/contact")}
          active={location.pathname === "/crm/contact"}
          nodeId="3_2"
          labelText="Contact"
          labelIcon={AccountCircleIcon}
          labelInfo="90"
          color="#1a73e8"
          bgColor="#fff3"
          colorForDarkMode="#B8E7FB"
          bgColorForDarkMode="#071318"
        />
      </StyledTreeItem>
      <StyledTreeItem nodeId="2" labelText="Invoice" labelIcon={ReceiptIcon} />
      <StyledTreeItem
        nodeId="4"
        labelText="Platform User"
        onClick={() => navigate("/user")}
        active={location.pathname === "/user"}
        labelIcon={BadgeIcon}
      />
      <StyledTreeItem
        nodeId="5"
        labelText="Booking Management"
        labelIcon={ShoppingCartIcon}
      />
      <StyledTreeItem nodeId="6" labelText="Settings" labelIcon={SettingsIcon}>
        <StyledTreeItem
          onClick={() => navigate("/setting/roles")}
          active={location.pathname === "/setting/roles"}
          nodeId="5"
          labelText="Roles"
          labelIcon={LockIcon}
          labelInfo="90"
        />
        <StyledTreeItem
          onClick={() => navigate("/setting/permissions")}
          active={location.pathname === "/setting/permissions"}
          nodeId="5"
          labelText="Permissions"
          labelIcon={LockIcon}
        />
        <StyledTreeItem
          nodeId="7"
          labelIcon={TipsAndUpdatesIcon}
          labelText="Plans"
        />
        <StyledTreeItem
          nodeId="8"
          labelText="Solutions"
          labelIcon={PostAddIcon}
        />
        <StyledTreeItem
          nodeId="8"
          labelText="Standard Templates"
          labelIcon={NewspaperIcon}
        />
      </StyledTreeItem>
    </TreeView>
  );
}
