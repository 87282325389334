import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import SimpleBarReact from 'simplebar-react';

type AppsContentContainerProps= {
  children: ReactNode;
  isDetailView?: boolean;
  fullView?: boolean;

  [x: string]: any;
}

const AppsContentContainer: React.FC<AppsContentContainerProps> = styled(
  SimpleBarReact
)((props: AppsContentContainerProps) => {
  return {
    width: "100%",
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& .simplebar-content": {
      height: "100%",
    },
  };
});

type AppsContentProps ={
  children: ReactNode;
  isDetailView?: boolean;
  fullView?: boolean;

  [x: string]: any;
}

const AppsContent: React.FC<AppsContentProps> = (props) => {
  return (
    <AppsContentContainer {...props}>{props.children}</AppsContentContainer>
  );
};

export default AppsContent;
