import { yupResolver } from "@hookform/resolvers/yup";
import { Close, Phone, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import AvatarUploader from "../../components/AvatarUploader";
import validationMessage from "../../constants/validationMessage";
import {
  PlatformUserEdit,
  PlatformUserIndex,
} from "../../interfaces/platformUser";
import { Role } from "../../interfaces/roleAndPermissionInterface";
import platformUserService from "../../services/platformUserService";
import dataSelect from "../../utils/data/dataSelect";
interface Props {
  openEditUser: boolean;
  setOpenEditUser: (data: boolean) => void;
  roles: Role[];
  isEditButton: boolean;
  selectedUser: PlatformUserIndex | undefined;
  getListUser: () => void;
}

const { titles, country } = dataSelect;

const EditUserModal = ({
  openEditUser,
  setOpenEditUser,
  roles,
  isEditButton,
  selectedUser,
  getListUser,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [avatar, setAvatar] = useState<any>([]);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isEditButton) {
      setIsEdit(false);
    }
  }, [isEditButton]);

  const refButtonSubmit = useRef<HTMLButtonElement>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object({
    email: yup
      .string()
      .required(validationMessage.required)
      .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
        message: validationMessage.email,
      }),
    title: yup.string().required(validationMessage.required),
    firstName: yup.string().required(validationMessage.required),
    lastName: yup.string().required(validationMessage.required),
    middleName: yup
      .string()
      .transform((value) => (value === null ? "" : value)),
    roleId: yup
      .number()
      .required(validationMessage.required)
      .transform((value) => (isNaN(value) ? undefined : value)),
    dob: yup.string().required(validationMessage.required),
    position: yup.string().required(validationMessage.required),
    department: yup.string().required(validationMessage.required),
    countryCode: yup.string().required(validationMessage.required),
    phoneNumber: yup
      .string()
      .required(validationMessage.required)
      .matches(phoneRegExp, "Phone number is not valid"),
    password: yup.string(),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<PlatformUserEdit>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (selectedUser && selectedUser.avatarUrl) {
      const isvalidAvatar = selectedUser?.avatarUrl?.length > 0;
      const listImage: (string | undefined)[] =
        selectedUser.avatarUrl !== null && isvalidAvatar
          ? [selectedUser.avatarUrl]
          : [];

      console.log(listImage);

      setAvatar(listImage);
      setPhoneNumber(selectedUser.countryCode);
    }
    reset(selectedUser);
  }, [reset, selectedUser]);

  const createUser: SubmitHandler<PlatformUserEdit> = async (formData) => {
    setIsLoading(true);
    if (selectedUser) {
      try {
        let response = await platformUserService.updatePlatformUser(
          { ...formData, avatarUrl: avatar?.length > 0 ? avatar[0] : "" },
          selectedUser.id
        );
        Swal.fire("", response?.data?.message, "success");
        // getListRoles();
      } catch (e: any) {
        Swal.fire({
          icon: "error",
          width: 700,
          text: e?.response?.data?.message,
          showCloseButton: true,
        });
      }
      getListUser();
      setOpenEditUser(false);
      setIsEdit(false);
      reset();
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openEditUser}
      onClose={() => setOpenEditUser(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
          bgcolor: "#0094d3",
          color: "#fff",
        }}
      >
        Platform User
        <IconButton aria-label="close" onClick={() => setOpenEditUser(false)}>
          <Close sx={{ color: "#fff" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: "#EFECEC", p: 0 }}>
        <Paper
          sx={{
            overflow: "hidden",
            m: 2,
            height: "100%",
            width: `calc(100vw - 310px)`,
          }}
        >
          <Box sx={{ p: 2 }}>
            <h3>User Information</h3>
          </Box>
          <form
            autoComplete={"off"}
            onSubmit={handleSubmit(createUser)}
            className={!isEdit ? "bg-readonly" : ""}
          >
            <Grid
              container
              spacing={1}
              sx={{
                pr: 2,
                m: 0,
                height: "100%",
                overflow: "hidden",
                overflowY: "auto",
              }}
              justifyContent="space-around"
            >
              <Grid item xs={12} md={12}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "0.75rem",
                    boxShadow: "unset",
                  }}
                >
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, md: 3 }}
                    mb={3}
                  >
                    <Grid
                      item
                      xs={12}
                      md={3}
                      display={"flex"}
                      justifyContent={"center"}
                      sx={{ mb: "0 !important" }}
                      borderRadius={"50%"}
                    >
                      <AvatarUploader
                        listImages={avatar}
                        setListImages={setAvatar}
                        page={"platformuser"}
                        widthImg={200}
                        heightImg={300}
                        idBtnUpload={"other-upload"}
                        classes={"d-flex"}
                        isEdit={isEdit}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid
                        container
                        rowSpacing={3}
                        sx={{ marginBottom: 0 }}
                        columnSpacing={{ xs: 1, md: 3 }}
                        mb={3}
                      >
                        {" "}
                        <Grid item xs={12} md={3}>
                          <Controller
                            control={control}
                            name="title"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="title"
                                  getOptionLabel={(option) => option.label}
                                  value={titles.find(
                                    (item) => item.value === value
                                  )}
                                  onChange={(e, value) => {
                                    onChange(value.value);
                                  }}
                                  size="small"
                                  readOnly={!isEdit}
                                  options={titles}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Title (*)"
                                      // defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.title !== undefined}
                                      helperText={
                                        errors.title !== undefined
                                          ? errors.title.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            autoComplete={"off"}
                            fullWidth
                            label="First Name (*)"
                            id="firstName"
                            {...register("firstName")}
                            error={errors.firstName !== undefined}
                            helperText={
                              errors.firstName !== undefined
                                ? errors.firstName.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ readOnly: !isEdit }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            fullWidth
                            autoComplete={"off"}
                            label="Middle Name"
                            id="middleName"
                            defaultValue={""}
                            {...register("middleName")}
                            error={errors.middleName !== undefined}
                            helperText={
                              errors.middleName !== undefined
                                ? errors.middleName.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ readOnly: !isEdit }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            fullWidth
                            autoComplete={"off"}
                            label="Last Name (*)"
                            id="lastName"
                            {...register("lastName")}
                            error={errors.lastName !== undefined}
                            helperText={
                              errors.lastName !== undefined
                                ? errors.lastName.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ readOnly: !isEdit }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ position: "relative" }}>
                              <Controller
                                control={control}
                                name="dob"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    readOnly={!isEdit}
                                    className={
                                      !isEdit ? "bg-datepicker-readonly" : ""
                                    }
                                    sx={{ width: "100%", height: "45px" }}
                                    label="Date of Birth (*)"
                                    format="DD/MM/YYYY"
                                    value={dayjs(value)}
                                    views={["year", "month", "day"]}
                                    onChange={(value: any) => {
                                      if (value) {
                                        onChange(value.format("YYYY-MM-DD"));
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.dob !== undefined && (
                                <small
                                  style={{
                                    color: "#d32f2f",
                                    position: "absolute",
                                    left: "10px",
                                    bottom: "-13px",
                                  }}
                                >
                                  {errors.dob.message}
                                </small>
                              )}
                            </Box>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            control={control}
                            name="countryCode"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  size="small"
                                  readOnly={!isEdit}
                                  disablePortal
                                  id="countryCode"
                                  getOptionLabel={(option) =>
                                    `(${option.value}) ${option.label}`
                                  }
                                  value={country.find(
                                    (item) => item.value === value
                                  )}
                                  onChange={(e, value) => {
                                    if (value) {
                                      onChange(value?.value);
                                      setPhoneNumber(value.value);
                                    }
                                  }}
                                  options={country}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country Code (*)"
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.countryCode !== undefined}
                                      helperText={
                                        errors.countryCode !== undefined
                                          ? errors.countryCode.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            autoComplete={"off"}
                            fullWidth
                            label="Phone (*)"
                            id="phoneNumber"
                            {...register("phoneNumber")}
                            error={errors.phoneNumber !== undefined}
                            helperText={
                              errors.phoneNumber !== undefined
                                ? errors.phoneNumber.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={isLoading}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <p>{phoneNumber}</p>
                                </InputAdornment>
                              ),
                              readOnly: !isEdit,
                              className: !isEdit
                                ? "bg-datepicker-readonly"
                                : "",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            inputProps={{ readOnly: !isEdit }}
                            autoComplete={"off"}
                            disabled={isLoading}
                            {...register("email")}
                            error={errors.email !== undefined}
                            helperText={
                              errors.email !== undefined
                                ? errors.email.message
                                : ""
                            }
                            fullWidth
                            // defaultValue={localStorage.getItem("email")}
                            label={"Email (*)"}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Position (*)"
                            id="position"
                            {...register("position")}
                            error={errors.position !== undefined}
                            helperText={
                              errors.position !== undefined
                                ? errors.position.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ readOnly: !isEdit }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            size="small"
                            fullWidth
                            label="Department (*)"
                            id="department"
                            {...register("department")}
                            error={errors.department !== undefined}
                            helperText={
                              errors.department !== undefined
                                ? errors.department.message
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ readOnly: !isEdit }}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            control={control}
                            name="roleId"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="roleId"
                                  getOptionLabel={(option) => option.name}
                                  value={roles.find(
                                    (item) => item.id === value
                                  )}
                                  onChange={(e, value) => {
                                    onChange(value.id);
                                  }}
                                  size="small"
                                  options={roles}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Role (*)"
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors.roleId !== undefined}
                                      helperText={
                                        errors.roleId !== undefined
                                          ? errors.roleId.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl
                            fullWidth
                            disabled={isLoading}
                            variant="outlined"
                            error={errors.password !== undefined}
                          >
                            <InputLabel
                              shrink={true}
                              sx={{
                                fontSize: "0.85rem",
                                zIndex: 1,
                                bgcolor: "#eee",
                                backgroundImage:
                                  "linear-gradient(180deg, white, #eee)",
                                p: "0 12px 0 14px",
                              }}
                              error={errors.password !== undefined}
                              htmlFor="outlined-adornment-password"
                            >
                              {`Password (*)`}
                            </InputLabel>
                            <OutlinedInput
                              {...register("password")}
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              error={errors.password !== undefined}
                              autoComplete="off"
                              inputProps={{ readOnly: !isEdit }}
                              size="small"
                              className={
                                !isEdit ? "bg-datepicker-readonly" : ""
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password (*)"
                            />
                            {errors.password !== undefined && (
                              <FormHelperText>
                                {errors.password.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <LoadingButton
              style={{ display: "none" }}
              ref={refButtonSubmit}
              className={"manual-button"}
              disabled={isLoading}
              type={"submit"}
              startIcon={<span></span>}
              loadingPosition={"start"}
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </form>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {isEditButton && (
          <LoadingButton
            onClick={() => setIsEdit(!isEdit)}
            variant="contained"
            sx={{
              bgcolor: isEdit ? "#808080" : "#0094d3",
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            disabled={isLoading}
            type={"submit"}
          >
            {isEdit ? "Cancel" : "Edit"}
          </LoadingButton>
        )}
        {isEdit && (
          <LoadingButton
            onClick={() => refButtonSubmit.current?.click()}
            className={"manual-button"}
            disabled={isLoading}
            type={"submit"}
            startIcon={<span></span>}
            loadingPosition={"start"}
            loading={isLoading}
          >
            Save
          </LoadingButton>
        )}
      </DialogActions>
    </Drawer>
  );
};

export default EditUserModal;
