import { Add, Close } from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Button,
  Chip,
  Collapse,
  FormHelperText,
  List,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import parse from "html-react-parser";
import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import { CompanyIndex, EmailSent } from "../../../interfaces/companyCRM";
import companyService from "../../../services/companyService";
import { convertUTCtoLocalTime } from "../../../utils";
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};
interface Props {
  selectedCompany: CompanyIndex | undefined;
  isEdit: boolean;
}
export default function EmailComponent({ selectedCompany, isEdit }: Props) {
  const [fetchEmail, setFetchEmail] = React.useState<EmailSent[]>([]);
  const [value, setValue] = React.useState("email");
  const [subjectEmail, setSubjectEmail] = React.useState("");
  const [errSubjectEmail, setErrSubjectEmail] = React.useState(false);
  const [errTxtSubjectEmail, setErrTxtSubjectEmail] = React.useState("");
  const [errMessageEmail, setErrMessageEmail] = React.useState(false);
  const [errTxtMessageEmail, setErrTxtMessageEmail] = React.useState("");
  const [messageEmail, setMessageEmail] = React.useState("");
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [openFetchEmail, setOpenFetchEmail] = React.useState(-1);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getFetchEmail = async (id: number) => {
    try {
      const response = await companyService.getEmailSent({ id: id });
      if (response.data) {
        setFetchEmail(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (selectedCompany) {
      getFetchEmail(selectedCompany.id);
    }
  }, [selectedCompany]);

  function EmailContent() {
    return (
      <>
        {fetchEmail.map((data: EmailSent, index: number) => (
          <List
            key={index}
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
          >
            <ListItemButton
              onClick={() =>
                setOpenFetchEmail(openFetchEmail === index ? -1 : index)
              }
              sx={{
                borderTop: "3px solid #2fc6f8",
                borderTopRightRadius: "0.5rem",
                borderTopLeftRadius: "0.5rem",
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ fontSize: "1rem", width: "100%", color: "#2fc6f8" }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.75rem", fontWeight: 500 }}
                >
                  {data.userCreate.firstName} {data.userCreate.lastName}
                </Typography>
                <Chip
                  sx={{ fontSize: "0.75rem" }}
                  label={convertUTCtoLocalTime(
                    data.createdAt,
                    "DD-MMM-YYYY H:m a"
                  )}
                  variant="outlined"
                  size="small"
                />
              </Stack>
              {openFetchEmail === index ? <Close /> : <Add />}
            </ListItemButton>
            <Collapse
              in={openFetchEmail === index}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" sx={{ py: 1, px: 2 }}>
                <Typography sx={{ fontSize: "0.75rem" }}>
                  <b>Subject:</b> {data?.subject}
                </Typography>
                <Typography sx={{ fontSize: "0.75rem" }}>
                  <b>Message:</b> {parse(data.content)}
                </Typography>
              </List>
            </Collapse>
          </List>
        ))}
      </>
    );
  }
  const sendEmail = async (e: any) => {
    e.preventDefault();
    if (subjectEmail === "") {
      setErrSubjectEmail(true);
      setErrTxtSubjectEmail("Please enter the subject");
      return false;
    }
    if (messageEmail === "") {
      setErrMessageEmail(true);
      setErrTxtMessageEmail("Please enter the message");
      return false;
    }
    if (selectedCompany) {
      try {
        const payload = {
          subject: subjectEmail,
          content: messageEmail,
        };
        setDisabledBtn(true);
        const response = await companyService.sendEmail(
          payload,
          selectedCompany?.id
        );
        if (response?.data) {
          setDisabledBtn(false);
          setSubjectEmail("");
          setMessageEmail("");
          getFetchEmail(selectedCompany?.id);

          setErrSubjectEmail(false);
          setErrTxtSubjectEmail("");
          setErrMessageEmail(false);
          setErrTxtMessageEmail("");
          Swal.fire("", response?.data?.message, "success");
        }
      } catch (e: any) {
        Swal.fire({
          icon: "error",
          width: 700,
          text: e?.response?.data?.message,
          showCloseButton: true,
        });
      }
      setDisabledBtn(false);
    }
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            sx={{
              "& .Mui-selected": {
                color: "#fff !important",
                backgroundColor: "#0094d3",
              },
            }}
            centered
          >
            <Tab
              sx={{ flex: 1, fontSize: "0.75rem" }}
              label="Email"
              value="email"
            />
            <Tab
              sx={{ flex: 1, fontSize: "0.75rem" }}
              label="Check Emails"
              value="check"
            />
          </TabList>
        </Box>
        <TabPanel
          value="email"
          sx={{ fontSize: "0.75rem", padding: "1rem 0.5rem" }}
        >
          <TextField
            id="outlined-multiline-subject"
            label="Subject"
            value={subjectEmail}
            onChange={(e) => setSubjectEmail(e.target.value)}
            error={errSubjectEmail}
            helperText={errTxtSubjectEmail}
            multiline
            fullWidth
            inputProps={{ readOnly: !isEdit }}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <Stack direction={"column"}>
            <Box sx={{ mb: 2, height: 150, overflowY: "auto" }}>
              <ReactQuill
                style={{ height: 100 }}
                readOnly={!isEdit}
                modules={modules}
                theme="snow"
                placeholder="Message here..."
                value={messageEmail}
                onChange={setMessageEmail}
              />
              {errMessageEmail ? (
                <FormHelperText error={true}>
                  {errTxtMessageEmail}
                </FormHelperText>
              ) : (
                ""
              )}
            </Box>

            <Button
              variant="outlined"
              color="info"
              sx={{
                mt: 2,
                textTransform: "capitalize",
                width: "50%",
                maxWidth: "150px",
                borderRadius: "8px",
              }}
              size="small"
              onClick={sendEmail}
              disabled={disabledBtn || !isEdit}
            >
              Send Email
            </Button>
          </Stack>
        </TabPanel>
        <TabPanel value="check" sx={{ padding: "1rem 0.5rem" }}>
          <EmailContent />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
