import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Swal from "sweetalert2";
import { Document } from "../../interfaces/common";
import imageService from "../../services/imageService";
import LoadingPage from "../Loading";



interface DocumentUploadInterface {
  documents: Document[];
  setDocuments: any;
  page: string;
  widthImg: number | string;
  heightImg: number | string;
  idBtnUpload: string;
  isEdit?: boolean
}
const DocumentUploader = ({
  page,
  widthImg,
  heightImg,
  documents,
  setDocuments,
  idBtnUpload,
  isEdit=true
}: DocumentUploadInterface) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    const reader: any = new FileReader();

    if (!file) {
      Swal.fire({
        icon: "error",
        text: "Please select an document.",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }

    if (
      file.size / 1024 >
      Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE)
    ) {
      Swal.fire({
        icon: "error",
        text:
          "Max file size is " +
          Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE) / 1024 +
          "MB!",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setSuccess(false);
      return;
    }

    reader.onload = async () => {
      setLoading(true);
      const res = await imageService
        .uploadImage(
          file,
          page,
          widthImg,
          heightImg,
          process.env.REACT_APP_BUCKET_S3,
          process.env.REACT_APP_BUCKET_FOLDER,
          process.env.REACT_APP_COMPANY_NAME
        )
        .then((res: any) => {
          if (res?.status === 200) {
            return res?.data?.response;
          }
          return;
        });

      if (res) {
        setDocuments([
          {
            name: res?.name,
            path: res?.path.replace(`/${process.env.REACT_APP_BUCKET_FOLDER}`, ""),
          }
        ]);
        setSuccess(true);
        setError("");
        Swal.fire({
          icon: "success",
          text: "Upload Image Success!",
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
        });
      } else {
        setSuccess(false);
        setError("Upload fail!");
        Swal.fire({
          icon: "error",
          text: "Upload Image Fail!",
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
        });
      }
      setLoading(false);
    };

    reader.readAsDataURL(file);
  };
  const VisuallyHiddenInput = styled("input")({
    height: "100%",
    position: "absolute",
    width: "100%",
    cursor: "pointer",
    top: 0,
    right: 0,
    zIndex: 99,
    fontSize: "13px",
    opacity: 0,
  });

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Stack
        sx={{
          position: "relative",
          minWidth: "64px",
          fontSize: "0.75rem",
          color: "#5b5b5b",
          bgcolor: "#f5f5f5",
          borderRadius: "8px",
          overflow: "hidden",
          mx: 3,
          px: 2,
          py: 1
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box className="col-auto" style={{ paddingLeft: "10px" }}>
          <Box
            mt={0}
            sx={{
              color: "#000",
              height: "100%",
            }}
          >
            <label htmlFor={idBtnUpload} role="button" className="">
              {loading ? (
                <LoadingPage widthLoading={40} heightLoading={40} />
              ) : (
                <span style={{ margin: "0 auto" }}>
                 Upload Document
                </span>
              )}
            </label>
            <VisuallyHiddenInput
              type="file"
              id={idBtnUpload}
              accept="image/png, image/jpeg, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
              onChange={handleImageChange}
              className="file-input"
              disabled={!isEdit}
            />
          </Box>
        </Box>
      </Stack>
      {error && !success && <Box sx={{ color: "red", mt: 1 }}>{error}</Box>}
    </Box>
  );
};

export default DocumentUploader;
