import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import tkgGifImage from '../assets/images/tkg_banner.png';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
type Props = {
    children?: React.ReactNode
}
const AuthLayout : React.FC<Props> = ({children}) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('accessToken') != null) navigate('/');
    }, []);

    return <Box sx={{
        height: "100vh"
    }}>
        <Grid id={"auth-layout"} container spacing={0}  >
            <Grid item  xl={7}  sm={12} className={"banner"} >
                    <img width={'100%'}  style={{
                        maxWidth: '100%',
                    }} src={tkgGifImage} alt=""/>

            </Grid>
            <Grid item  xl={5}  sm={12} className={"content-auth"}>
                    {children}

            </Grid>
        </Grid>
    </Box>
}

export default AuthLayout;
