import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { Permission } from '../../interfaces/roleAndPermissionInterface'
import permissionService from '../../services/permissionService'

interface Props {
    isEdit: boolean
    setIsEdit: (data: boolean) => void
    data: Permission
    parentCallback: () => void
}
const ModalAction = ({isEdit, setIsEdit, data, parentCallback} : Props) => {

    const [perMissionName, setPerMissionName] = useState(data.name)
    const [loading, setLoading] = useState(false)

    const handleEditPermission = async (event: any) => {
        event.preventDefault();
        const payload = {
          id: data.id,
          name: perMissionName,
        };
    
        if (perMissionName?.length > 0) {
            setLoading(true)
          try {
            const response = await permissionService.updatePermission(payload);
            Swal.fire("", response?.data?.message, "success");
            parentCallback();
          } catch (e: any) {
            Swal.fire("", e?.data?.message, "error");
          }
          setLoading(false)
        //   clearFormCreate();
        }
      };
  return (
    <Dialog open={isEdit} fullWidth={true} maxWidth={"md"}>
    <DialogTitle>Edit Permission</DialogTitle>
    <DialogContent>
        <DialogContentText>
          Please make sure to only edit permissions related to system
          functionality.
        </DialogContentText>

      <form autoComplete={"off"}>
        <TextField
          autoComplete={"off"}
          required={true}
          error={perMissionName?.length > 0 ? false : true}
          helperText={
            perMissionName?.length > 0 ? null : "Enter permission name"
          }
          autoFocus
          margin="dense"
          id={"outlined-error-helper-text"}
          label="Name"
          type="text"
          fullWidth
          value={perMissionName}
          onChange={(e) => setPerMissionName(e.target.value)}
        />
        <DialogActions style={{ justifyContent: "space-between" }}>
       
          <LoadingButton
            onClick={() => setIsEdit(!isEdit)}
            variant="contained"
            sx={{
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            className={"button-gray"}
            disabled={loading}
            type={"submit"}
          >
            Cancel
          </LoadingButton>
      
        {isEdit && (
          <LoadingButton
            onClick={handleEditPermission}
            className={"manual-button"}
            disabled={loading}
            type={"submit"}
            startIcon={<span></span>}
            loadingPosition={"start"}
            loading={loading}
          >
            Save
          </LoadingButton>
        )}
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
  )
}

export default ModalAction