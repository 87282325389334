import { Box, MenuItem, Table, TableBody, TableHead, TablePagination, TextField, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import AppTableContainer from "./AppTableContainer";
import AppsContent from "./AppsContent";


interface Props {
    heading?: string
    extraActions?: ReactNode
    data: any,
    getListTable: () => void
    tableHeading: ReactNode
    tableContent: ReactNode
    page: number 
    setPage: (data: number) => void
    perPage: number 
    setPerPage: (data: number) => void
    filterText:string 
    setFilterText: (data: string) => void
}
const ContentLayout = ({heading= "", extraActions, data, getListTable, tableHeading, tableContent, page, setPage, perPage, setPerPage, filterText, setFilterText}: Props) => {
    const [dataTable, setDataTable] = useState([]);
  
    const onPageChange = (e: any) => {
      if (
        e?.target?.dataset?.testid === 'KeyboardArrowLeftIcon' ||
        e?.target?.farthestViewportElement?.dataset?.testid ===
        'KeyboardArrowLeftIcon' ||
        e?.target?.ariaLabel === 'Previous Page'
      ) {
        setPage(page - 1);
      }
      if (
        e?.target?.dataset?.testid === 'KeyboardArrowRightIcon' ||
        e?.target?.farthestViewportElement?.dataset?.testid ===
        'KeyboardArrowRightIcon' ||
        e?.target?.ariaLabel === 'Next Page'
      ) {
        setPage(page + 1);
      }
    };
  
    useEffect(() => {
      setPage(1);
    }, [])
    useEffect(() => {
      if(data) {
        setDataTable(data);
      }
    }, [data])
  
    return <Box width={'100%'}>
        <Box sx={{ padding: "20px", background: '#0094d3', marginBottom: "20px" }}>
        <Typography variant='h3' color="white" textTransform={'uppercase'} sx={{fontSize: "18px"}}>{heading}</Typography>
      </Box>

      <Box sx={{ background: "#fff", p: 4, borderRadius: "12px", height: 'calc(100vh - 250px)' }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            {extraActions}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField size='small' value={filterText} onChange={(e) => setFilterText(e?.target?.value)} label='Search Title' InputLabelProps={{ shrink: true }} />
            &ensp; Show &ensp;
            <TextField select value={perPage} onChange={(e) => setPerPage(Number(e?.target?.value))} sx={{ width: 100, height: "45px" }}>
              {[10, 15, 20, 50, 100, 150]?.map((e: number, i: number) => (
                <MenuItem key={i} value={e}>{e}</MenuItem>
              ))}
            </TextField>
            <TablePagination
              component="div"
              count={dataTable?.length}
              rowsPerPage={perPage}
              page={page - 1}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onPageChange={onPageChange}
              rowsPerPageOptions={[]}
            />
          </Box>
        </Box>
        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
            height: 'calc(100% - 50px)',
          }}
        >
          <AppTableContainer>
      <Table stickyHeader className="table">
        <TableHead>
          {tableHeading}
        </TableHead>
        <TableBody>
          {tableContent}
        </TableBody>
      </Table>
    </AppTableContainer>
       
        </AppsContent>
      </Box>
    </Box>
}

export default ContentLayout;
