import { Close } from "@mui/icons-material";
import {
  Box,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton
} from "@mui/material";
import { History, Role } from "../../interfaces/roleAndPermissionInterface";
import HistoryComponent from "./HistoryComponent";
import HistoryComponent2 from "./HistoryComponent2";

interface Props {
  openDialogShowHistory: boolean;
  setOpenDialogShowHistory: (data: boolean) => void;
  data: History[];
  title: string;
  type?: string
  listRole?: Role[]
}
const ShowHistory = ({
  openDialogShowHistory,
  setOpenDialogShowHistory,
  data,
    title,
    type = 'normal',
    listRole = []
}: Props) => {
  return (
    <Drawer
      anchor="right"
      open={openDialogShowHistory}
      onClose={() => setOpenDialogShowHistory(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialogShowHistory(false)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{p: 0}}>
        {data.length > 0 ? <>
         {type === 'normal' && <HistoryComponent data={data} />}
        {type === 'user' && <HistoryComponent2 data={ data} listRole={listRole} />}
        </> : <Box sx={{width: `calc(100vw - 280px)`, p: 2, fontSize: "18px"}}>No history to show</Box>}
       
      </DialogContent>
    </Drawer>
  );
};

export default ShowHistory;
