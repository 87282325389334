import { Add } from "@mui/icons-material";
import {
  Box,
  Button
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import LoadingPage from "../../components/Loading";
import ContentLayout from "../../components/layouts/ContentLayout";
import { PlatformUserIndex } from "../../interfaces/platformUser";
import { Role, RoleToPermission } from "../../interfaces/roleAndPermissionInterface";
import platformUserService from "../../services/platformUserService";
import roleService from "../../services/roleService";
import CreateUserModal from "./CreateUserModal";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

const PlatFormUserPage = () => {
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [listUsers, setListUsers] = useState<PlatformUserIndex[]>([]);  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleID, setSelectedRoleID] = useState(0);
  const [listPermissions, setListPermissions] = useState<any[]>([]);
  const [rolePermissions, setRolePermissions] = useState<RoleToPermission[]>(
    []
  );
    const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [roles, setRoles] = useState<Role[]>([]);
  
  const getListRoles = async () => {
    setLoading(true)
    try {
      const response = await roleService.getList();
      const roles: Role[] = response.data;
      setRoles(roles);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false)
  };
  const getListUser = async () => {
    setLoading(true)
    try {
      const response = await platformUserService.getList();
      const user: PlatformUserIndex[] = response.data;
      setListUsers(user);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false)
  };

  useEffect(() => {
    getListRoles();
    getListUser()
  }, []);
  
  const clearFormCreate = () => {
    setOpenDialogCreate(false);
    setViewMode(false);
    setSelectedRoleID(0);
    setRoleName("");
  };

  const listData = useMemo(() => {
    //Current Page slice
    let baseData = listUsers;

    if (filterText)
      baseData = baseData.filter((e: any) =>
      (`${e.firstName ? e.firstName : ""} ${
        e.middleName ? e.middleName : ""
      } ${e.lastName ? e.lastName : ""}`)?.toLowerCase().trim().includes(filterText?.toLowerCase().trim())
      );

    return baseData?.slice(
      (page - 1) * perPage,
      (page - 1) * perPage + perPage
    );
  }, [listUsers, page, perPage, filterText]);

  return (
    <>
      {
      loading ? <Box
      sx={{
        width: `calc(100vw - 360px)`,
        overflow: "hidden",
        m: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "82vh",
        background: "#f5f5f5",
      }}
    >
      <LoadingPage />
    </Box> :
      <ContentLayout
        heading="Platform User Page"
        extraActions={
          <Button
            variant={"contained"}
            size="medium"
            onClick={() => {
              clearFormCreate()
              setOpenDialogCreate(true);
            }}
            sx={{ textTransform: "capitalize" }}
            className="bg-color-main"
            endIcon={<Add />}
          >
            Add User
          </Button>
        }
        data={listUsers}
        getListTable={getListUser}
        tableHeading={<TableHeading />}
        tableContent={listData.map((e: any, i: number) => (
          <TableItem
            key={i}
            data={e}
            dataKey={i}
            roles={roles}
            rolePermissions={rolePermissions}
            setRolePermissions={setRolePermissions}
            listPermissions={listPermissions}
            childrenCallback={getListUser}
          />
        ))}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        filterText={filterText}
        setFilterText={setFilterText}
      />}
      {openDialogCreate && (
          <CreateUserModal setOpenCreateUser={setOpenDialogCreate} openCreateUser={openDialogCreate} roles={roles} getListUser={getListUser} />
      )}
    </>
  );
};

export default PlatFormUserPage;
