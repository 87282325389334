import axios from "axios";
const defaultHeader = {
};
const token = localStorage.getItem("accessToken");
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 9000,
  headers: defaultHeader,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);

export const postFile = async ({ url, payload, headers = defaultHeader }: any) => {
    const token = localStorage.getItem('accessToken');
    const authHeader = {
      Authorization: `Bearer ${token}`
    }
    const res = await instance.post(url, payload, {
      headers: token ? {
        ...authHeader,
        ...headers,
        'Content-Type': 'multipart/form-data'
      } : { ...headers, 'Content-Type': 'multipart/form-data' },
    });
    return res;
  };