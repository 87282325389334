import { postFile } from "../config/apiFunction";
import endPointAPI from "../constants/endPointAPI";

const imageService = {
  uploadImage: async (
    data: any,
    page: string,
    widthImg: any,
    heightImg: any,
    bucket: string | undefined,
    bucket_folder: string | undefined,
    company: string | undefined
  ) => {
    const form = new FormData();
    if (data?.name) {
      console.log(data);

      form.append("image", data);
      form.append("width", widthImg);
      form.append("height", heightImg);
      form.append("page", page);
      form.append("bucket", bucket ?? "");
      form.append("bucket_folder", bucket_folder ?? "");
      form.append("company", company ?? "");

      return await postFile({
        url: `${process.env.REACT_APP_UPLOAD_API_URL}/${endPointAPI.IMAGE.UPLOAD_IMAGE}`,
        payload: form,
        headers: process.env.REACT_APP_UPLOAD_IMAGE_LOCAL ? {"x-api-key": "clZvSRq5BYftEyNtmX2PiUIdMfkz5E3ljswW12iWEDIZtK4SSnsRzvimrXz6l04T"} : {}
      });
    }
    return;
  },
};

export default imageService;
