import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  Box,
  Container,
  Stack,
} from "@mui/material";
import { History, Role } from "../../interfaces/roleAndPermissionInterface";
import { convertUTCtoLocalTime } from "../../utils";

interface Props {
    data: History[];
    listRole: Role[]
}
const HistoryComponent2 = ({data, listRole}: Props) => {
  const renderUserField = (field: string) => {
    switch(field) {
      case "title": 
      return "Title"
      case "firstName" :
        return "First Name"
      case "lastName" :
        return "Last Name"
      case "middleName" :
        return "Middle Name"
      case "roleId" :
        return "Role"
      case "email" :
        return "Email"
      case "dob" :
        return "Date of Birth"
      case "position" :
        return "Position"
      case "department" :
        return "Department"
      case "countryCode" :
        return "Country Code"
         
      case "phoneNumber" :
        return "Phone Number"
         
      case "password" :
        return "Password"
      case "avatarUrl" :
        return "Avatar"
         
      default: 
      return field
    }
  }

  const renderRole = (roleId: number) => {
    return listRole.find(item => item.id === roleId)
  }
  const renderAvatar = (avatar: any) => {
    if(avatar !== null && avatar.length > 0) {
         return `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${avatar}` 
    } 
    return avatar

  }
  return (
    <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: {
        flex: 0.35,
      }, width: `calc(100vw - 310px)`, m: 2  }}>
        {data.map((item, index) => {
          const events = JSON.parse(item.events ?? "");

          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent color="text.secondary">
                <Box>
                  <p style={{color: "black"}}>{convertUTCtoLocalTime(item.createdAt)}</p>
                  <p>
                    Action by{" "}
                    <span
                      style={{ fontWeight: 600, color: "black" }}
                    >{`${item.userCreate.firstName} ${item.userCreate.lastName}`}</span>
                  </p>
                </Box>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <>
                  {events?.map((element: any, ind: number) => {

                      return (
                        <Container
                          maxWidth="xl"
                          sx={{ borderBottom: "1px solid #f5f5f5", m: 0, p: 0 }}
                          key={ind}
                        >
                          <Stack
                            spacing={{ xs: 0, sm: 1 }}
                            direction="row"
                            useFlexGap
                            flexWrap="wrap"
                          >
                            <div style={{ fontWeight: 600 }}>Change</div>
                            <div
                              style={{ color: "#0094d3" }}
                            >{`\"${renderUserField(element.field)}\"`}</div>
                            <div style={{ fontWeight: 600 }}>from:</div>{" "}
                            <div style={{ color: "black" }}>
                              <i>{`\"${element.field === "roleId" ? renderRole(element.oldValue)?.name : element.field === "avatarUrl" ? renderAvatar(element.oldValue) : element.oldValue}\"`}</i>
                            </div>
                            <div style={{ fontWeight: 600 }}>to:</div>
                            <div style={{ color: "#008000" }}>
                            <i>{`\"${element.field === "roleId" ? renderRole(element.newValue)?.name : element.field === "avatarUrl" ? renderAvatar(element.newValue) : element.newValue}\"`}</i>
                            </div>
                          </Stack>
                        </Container>
                      );

                  })}
                </>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
  )
}

export default HistoryComponent2
