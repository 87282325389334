import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { Permission } from '../../interfaces/roleAndPermissionInterface'

interface Props {
    showDetail: boolean
    setShowDetail: (data: boolean) => void
    data: Permission
}
const ModalView = ({showDetail, setShowDetail, data} : Props) => {
  return (
    <Dialog open={showDetail} fullWidth={true} maxWidth={"md"}>
    <DialogTitle>View Permission</DialogTitle>
    <DialogContent>
      <form autoComplete={"off"}>
        <TextField
          autoComplete={"off"}
          required={true}
          error={data.name?.length > 0 ? false : true}
          helperText={
            data.name?.length > 0 ? null : "Enter permission name"
          }
          inputProps={{ readOnly: true, className: "bg-datepicker-readonly"}}
          margin="dense"
          id={"outlined-error-helper-text"}
          label="Name"
          type="text"
          fullWidth
          defaultValue={data.name}
          
        />
        <DialogActions style={{ justifyContent: "flex-end" }}>
       
          <LoadingButton
            onClick={() => setShowDetail(!showDetail)}
            variant="contained"
            sx={{
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            className={"button-gray"}
            type={"submit"}
          >
            Cancel
          </LoadingButton>
      
       
        </DialogActions>
      </form>
    </DialogContent>
  </Dialog>
  )
}

export default ModalView