import { createBrowserRouter } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import ForgotPasswordPage from "../pages/authentication/ForgotPasswordPage";
import ForgotPasswordRequestPage from "../pages/authentication/ForgotPasswordRequestPage";
import SignInPage from '../pages/authentication/SignInPage';
import CompanyPage from "../pages/company";
import ContactPage from "../pages/contact";
import Dashboard from "../pages/dashboard/Dashboard";
import PermissionPage from "../pages/permissions/PermissionPage";
import PlatformUser from "../pages/platform-user";
import RolePage from "../pages/roles/RolePage";

const router = createBrowserRouter(
    
    
    [
        {
            path: "/",
            element: <AppLayout />,
            // loader: <LoadingPage />,
            children: [
                {
                    path: "/",
                    element: <Dashboard />
                },
                {
                    path: "/user",
                    element: <PlatformUser />
                },
                {
                    path: "/crm",
                    children: [
                        {
                            path: "company",
                            element: <CompanyPage />
                        },
                        {
                            path: "contact",
                            element: <ContactPage />
                        }
                    ]
                },
                {
                    path: "/setting",
                    children: [
                        {
                            path: "permissions",
                            element: <PermissionPage />
                        },
                        {
                            path: "roles",
                            element: <RolePage />
                        }
                    ]
                }
            ],
          },
    {
        path: "/sign-in",
        element: <SignInPage />
    },
    {
        path: "/forgot-password-request",
        element: <ForgotPasswordRequestPage />
    },
   
    {
        path: "/forgot-password/:token",
        element: <ForgotPasswordPage />
    }, 
   
])


export default router;
