import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";
import LoadingPage from "../../components/Loading";
import ContentLayout from "../../components/layouts/ContentLayout";
import { Permission } from "../../interfaces/roleAndPermissionInterface";
import permissionService from "../../services/permissionService";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

const PermissionPage = () => {
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [perMissionName, setPerMissionName] = useState("");
  const [selectedPerMissionID, setSelectedPerMissionID] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");

  const refFormCreate = useRef<HTMLFormElement>(null);
  const getListPermissions = async () => {
    setLoading(true);
    try {
      const response = await permissionService.getList();
      const permissions: Permission[] = response.data;
      setPermissions(permissions);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    getListPermissions();
  }, []);
  const clearFormCreate = () => {
    setOpenDialogCreate(false);
    setViewMode(false);
    setSelectedPerMissionID(0);
    setPerMissionName("");
  };
  const createPermission = async (event: any) => {
    event.preventDefault();
    const payload = {
      name: perMissionName,
    };
    if (perMissionName?.length > 0) {
      try {
        const response = await permissionService.createPermission(payload);
        Swal.fire("", response?.data?.message, "success");
        getListPermissions();
      } catch (e: any) {
        Swal.fire("", e?.data?.message, "error");
      }
      clearFormCreate();
    }
  };

  const listData = useMemo(() => {
    //Current Page slice
    let baseData = permissions;

    if (filterText)
      baseData = baseData.filter((e: any) =>
        e?.name?.toLowerCase().trim().includes(filterText?.toLowerCase().trim())
      );

    return baseData?.slice(
      (page - 1) * perPage,
      (page - 1) * perPage + perPage
    );
  }, [permissions, page, perPage, filterText]);

  return (
    <>
    {
      loading ? <Box
      sx={{
        width: `calc(100vw - 360px)`,
        overflow: "hidden",
        m: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "82vh",
        background: "#f5f5f5",
      }}
    >
      <LoadingPage />
    </Box> :
      <ContentLayout
        heading="Permission Page"
        extraActions={
          <Button
            variant={"contained"}
            size="medium"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{ textTransform: "capitalize" }}
            className="bg-color-main"
            endIcon={<Add />}
          >
            Add Permission
          </Button>
        }
        data={permissions}
        getListTable={getListPermissions}
        tableHeading={<TableHeading />}
        tableContent={listData.map((e: any, i: number) => (
          <TableItem
            key={i}
            data={e}
            dataKey={i}
            childrenCallback={getListPermissions}
          />
        ))}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        filterText={filterText}
        setFilterText={setFilterText}
      />}
      {openDialogCreate && (
        <Dialog open={openDialogCreate} fullWidth={true} maxWidth={"md"}>
          <DialogTitle>Create Permission</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please make sure to only create permissions related to system
              functionality.
            </DialogContentText>

            <form ref={refFormCreate} autoComplete={"off"}>
              <TextField
                autoComplete={"off"}
                disabled={viewMode}
                required={true}
                error={perMissionName?.length > 0 ? false : true}
                helperText={
                  perMissionName?.length > 0 ? null : "Enter permission name"
                }
                autoFocus
                margin="dense"
                id={"outlined-error-helper-text"}
                label="Name"
                type="text"
                fullWidth
                value={perMissionName}
                onChange={(e) => setPerMissionName(e.target.value)}
              />
              <DialogActions style={{ justifyContent: "space-between" }}>
                <Button className={"button-gray"} onClick={clearFormCreate}>
                  Cancel
                </Button>

                <Button
                  className={"manual-button"}
                  disabled={viewMode}
                  type={"submit"}
                  onClick={(e) => createPermission(e)}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PermissionPage;
