import { yupResolver } from "@hookform/resolvers/yup";
import {
  Close,
  Language,
  Phone,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import AvatarUploader from "../../components/AvatarUploader";
import LoadingPage from "../../components/Loading";
import validationMessage from "../../constants/validationMessage";
import {
  CompanyIndex,
  detailCompany,
  requestEditCompany,
} from "../../interfaces/companyCRM";
import { ContactIndex } from "../../interfaces/contactCRM";
import { Plan, Solution } from "../../interfaces/solutionAndPlanInterface";
import companyService from "../../services/companyService";
import solutionService from "../../services/solutionService";
import dataSelect from "../../utils/data/dataSelect";
import ContactComponent from "./contact/ContactComponent";
import EmailComponent from "./email/EmailComponent";
import NotesComponent from "./notes/NotesComponent";
import AddressComponent from "../../components/AddressComponent.tsx";
interface Props {
  openEditUser: boolean;
  setOpenEditUser: (data: boolean) => void;
  getListCompany: () => void;
  isEditButton: boolean;
  selectedCompany: CompanyIndex | undefined;
}
const cssBodyModal = {
  pr: 2,
  // pl: 1,
  // py: 2,
  m: 0,
  // height: '80%',
  height: "calc(100vh - 11rem)",
  overflow: "hidden",
  overflowY: "auto",
  // background: '#fff',
};

const { country, companyType, status, phoneType } = dataSelect;

const EditCompanyModal = ({
  openEditUser,
  setOpenEditUser,
  getListCompany,
  isEditButton,
  selectedCompany,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState<string[]>([]);
  const [listSolution, setListSolution] = useState<Solution[]>([]);
  const [listContactCompany, setListContactCompany] = useState<ContactIndex[]>([]);
  const [listPlan, setListPlan] = useState<Plan[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [companyDetail, setCompanyDetail] = useState<
    detailCompany | undefined
  >();

  const refButtonSubmit = useRef<HTMLButtonElement>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object({
    name: yup.string().required(validationMessage.required),
    solutionId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value)).transform((value) => (value === null ? undefined : value)),
    planId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value)).transform((value) => (value === null ? undefined : value)),
    status: yup.string().required(validationMessage.required),
    type: yup.string().required(validationMessage.required),
    mainEmail: yup
      .string()
      .required(validationMessage.required)
      .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
        message: validationMessage.email,
      }),
    mainEmailType: yup.string().required(validationMessage.required),
    secondEmail: yup.string().transform((value) => (value === null ? "" : value)),
    // .matches(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g), {
    //   message: validationMessage.email,
    // }),
    secondEmailType: yup.string().transform((value) => (value === null ? "" : value)),
    countryCode: yup.string().required(validationMessage.required),
    phoneNumber: yup
      .string()
      .required(validationMessage.required)
      .matches(phoneRegExp, "Phone number is not valid"),
    phoneType: yup.string().required(validationMessage.required),
    countryCodeSecond: yup.string(),
    phoneNumberSecond: yup.string(),
    phoneTypeSecond: yup.string(),
    websiteUrl: yup.string().required(validationMessage.required),
    address: yup.string().required(validationMessage.required),
    city: yup.string().required(validationMessage.required),
    country: yup.string().required(validationMessage.required),
    region: yup.string().required(validationMessage.required),
    postcode: yup.string().required(validationMessage.required),
    mainContactId: yup
      .number()
      .transform((value) => (value === null ? undefined : value)),
    frontEndUrl: yup.string().transform((value) => (value === null ? "" : value)),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<requestEditCompany>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getDetailCompany = async (id: number) => {
    setLoading(true);
    try {
      const response = await companyService.getDetail({ id: id });
      const companyData: detailCompany = response.data;
      setCompanyDetail(companyData);
      if (companyData && companyData.logoUrl) {
        const isvalidAvatar = companyData?.logoUrl?.length > 0;
        const listImage: string[] =
          companyData.logoUrl !== null && isvalidAvatar
            ? [companyData.logoUrl]
            : [];
        setAvatar(listImage);
      }
      setPhoneNumber(companyData.countryCode)
      // const solution: Solution = companyData.solution;
      if (listSolution?.length > 0) {

        console.log(listSolution, "89909");
        
        const listPlan: Solution | undefined = listSolution.find((item: Solution) => item.id === companyData.solutionId);

        if(listPlan) {
            setListPlan(listPlan.plans);
        }
      
      }
      reset(companyData);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false);
  };
  const getListSolution = async () => {
    try {
      const response = await solutionService.getList();
      const solutions: Solution[] = response.data;
      setListSolution(solutions);
    } catch (e: any) {
      console.log(e);
    }
  };
  useEffect(() => {
    getListSolution();
  }, []);
  const getListContactCompany = async (id: number) => {
    try {
      const response = await companyService.getContactCompany({id: id});
      const contacts: ContactIndex[] = response.data;
      setListContactCompany(contacts);
    } catch (e: any) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (selectedCompany && selectedCompany.id && listSolution.length > 0) {
      getDetailCompany(selectedCompany.id);
      getListContactCompany(selectedCompany.id);
    }
  }, [selectedCompany, listSolution]);

  useEffect(() => {
    if (isEditButton) {
      setIsEdit(false);
    }
  }, [isEditButton]);

  const updateCompany: SubmitHandler<requestEditCompany> = async (formData) => {
    setIsLoading(true);
    if (selectedCompany) {
      try {
        let response = await companyService.updateCompany(
          {
            ...formData,
            logoUrl: avatar?.length > 0 ? avatar[0] : "",
          },
          selectedCompany?.id
        );
        Swal.fire("", response?.data?.message, "success");
        getListCompany();
        // setOpenEditUser(false);
        reset();
      } catch (e: any) {
        Swal.fire({
          icon: "error",
          width: 700,
          text: e?.response?.data?.message,
          showCloseButton: true,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openEditUser}
      onClose={() => setOpenEditUser(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
          bgcolor: "#0094d3",
          color: "#fff",
        }}
      >
        CRM - Company
        <IconButton aria-label="close" onClick={() => setOpenEditUser(false)}>
          <Close sx={{ color: "#fff" }} />
        </IconButton>
      </DialogTitle>
      {(loading) ? (
        <Paper
          sx={{
            width: `calc(100vw - 310px)`,
            overflow: "hidden",
            m: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            background: "#f5f5f5",
          }}
        >
          <LoadingPage />
        </Paper>
      ) : (
        <DialogContent sx={{ bgcolor: "#EFECEC", p: 0 }}>
          <Box
            sx={{
              width: `calc(100vw - 310px)`,
              overflow: "hidden",
              m: 2,
              height: "fit-content",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={cssBodyModal}
              justifyContent="space-around"
            >
              <Grid item xs={12} md={8}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "0.75rem",
                  }}
                >
                  <form onSubmit={handleSubmit(updateCompany)} className={!isEdit ? "bg-readonly" : ""}>
                    <Grid
                      container
                      spacing={1}
                      sx={cssBodyModal}
                      justifyContent="space-around"
                    >
                      <Grid item xs={12} md={12}>
                        <Paper
                          sx={{
                            p: 2,
                            borderRadius: "0.75rem",
                            boxShadow: "unset",
                          }}
                        >
                          <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, md: 3 }}
                            mb={3}
                          >
                            <Grid
                              item
                              xs={12}
                              md={4}
                              display={"flex"}
                              justifyContent={"center"}
                              sx={{ mb: "0 !important" }}
                              borderRadius={"50%"}
                            >
                              <AvatarUploader
                                listImages={avatar}
                                setListImages={setAvatar}
                                page={"company-crm"}
                                widthImg={200}
                                heightImg={300}
                                idBtnUpload={"other-upload"}
                                classes={"d-flex"}
                                isEdit={true}
                              />
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Grid
                                container
                                rowSpacing={3}
                                sx={{ marginBottom: 0 }}
                                columnSpacing={{ xs: 1, md: 3 }}
                                mb={3}
                              >
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    label="Name "
                                    id="name"
                                    {...register("name")}
                                    error={errors.name !== undefined}
                                    helperText={
                                      errors.name !== undefined
                                        ? errors.name.message
                                        : ""
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{ readOnly: !isEdit }}
                                    disabled={isLoading}
                                  />
                                </Grid>{" "}
                                <Grid item xs={12} md={6}>
                                  <Controller
                                    control={control}
                                    name="type"
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => {
                                      return (
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id="type"
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          value={companyType.find(
                                            (item) => item?.value === value
                                          )}
                                          onChange={(e, value) => {
                                            onChange(value?.value);
                                          }}
                                          size="small"
                                          readOnly={!isEdit}
                                          options={companyType}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Type "
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              error={errors.type !== undefined}
                                              helperText={
                                                errors.type !== undefined
                                                  ? errors.type.message
                                                  : ""
                                              }
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    label="Website Url"
                                    id="websiteUrl"
                                    {...register("websiteUrl")}
                                    error={errors.websiteUrl !== undefined}
                                    helperText={
                                      errors.websiteUrl !== undefined
                                        ? errors.websiteUrl.message
                                        : ""
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    disabled={isLoading}
                                    InputProps={{
                                      readOnly: !isEdit,
                                      className: !isEdit ? "bg-datepicker-readonly" : "",
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Language fontSize="medium" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Controller
                                    control={control}
                                    name="status"
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => {
                                      return (
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id="status"
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          value={status.find(
                                            (item) => item?.value === value
                                          )}
                                          onChange={(e, value) => {
                                            onChange(value?.value);
                                          }}
                                          size="small"
                                          readOnly={!isEdit}
                                          options={status}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Status "
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              error={
                                                errors.status !== undefined
                                              }
                                              helperText={
                                                errors.status !== undefined
                                                  ? errors.status.message
                                                  : ""
                                              }
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Controller
                                    control={control}
                                    name="solutionId"
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => {
                                      return (
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id="solutionId"
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          value={listSolution.find(
                                            (item) => item?.id === value
                                          )}
                                          onChange={(e, value) => {
                                            console.log(value);

                                            onChange(value?.id);
                                            if (value) {
                                              setListPlan(value?.plans);
                                            }
                                          }}
                                          size="small"
                                          readOnly={!isEdit}
                                          options={listSolution}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Solution "
                                              // defaultValue={""}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              error={
                                                errors.solutionId !== undefined
                                              }
                                              helperText={
                                                errors.solutionId !== undefined
                                                  ? errors.solutionId.message
                                                  : ""
                                              }
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Controller
                                    control={control}
                                    name="planId"
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => {
                                      return (
                                        <Autocomplete
                                          disablePortal
                                          id="planId"
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          value={listPlan.find(
                                            (item) => item?.id === value
                                          )}
                                          onChange={(e, value) =>
                                            onChange(value?.id)
                                          }
                                          size="small"
                                          readOnly={!isEdit}
                                          options={listPlan}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Plan "
                                              // defaultValue={""}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              error={
                                                errors.planId !== undefined
                                              }
                                              helperText={
                                                errors.planId !== undefined
                                                  ? errors.planId.message
                                                  : ""
                                              }
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                disabled={isLoading}
                                {...register("mainEmail")}
                                error={errors.mainEmail !== undefined}
                                helperText={
                                  errors.mainEmail !== undefined
                                    ? errors.mainEmail.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                size="small"
                                inputProps={{ readOnly: !isEdit }}
                                fullWidth
                                label={"Main Email"}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {companyDetail && <Controller
                                control={control}
                                name="mainEmailType"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                    freeSolo
                                    autoSelect
                                      disablePortal
                                      disableClearable
                                      id="mainEmailType"
                                      getOptionLabel={(option) => option}
                                      value={(phoneType.includes(companyDetail?.mainEmailType) ? phoneType : [...phoneType, companyDetail?.mainEmailType]).find(
                                        (item) => item === value
                                      )}
                                      onChange={(e, value) => {
                                        onChange(value);
                                      }}
                                      size="small"
                                      readOnly={!isEdit}
                                      options={(phoneType.includes(companyDetail?.mainEmailType) ? phoneType : [...phoneType, companyDetail?.mainEmailType])}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Type"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={
                                            errors.mainEmailType !== undefined
                                          }
                                          helperText={
                                            errors.mainEmailType !== undefined
                                              ? errors.mainEmailType.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                disabled={isLoading}
                                {...register("secondEmail")}
                                error={errors.secondEmail !== undefined}
                                helperText={
                                  errors.secondEmail !== undefined
                                    ? errors.secondEmail.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                size="small"
                                inputProps={{ readOnly: !isEdit }}
                                fullWidth
                                label={"Email 2"}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {companyDetail && <Controller
                                control={control}
                                name="secondEmailType"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                    freeSolo
                                    autoSelect
                                      disablePortal
                                      disableClearable
                                      id="secondEmailType"
                                      getOptionLabel={(option) => option}
                                      value={(phoneType.includes(companyDetail?.secondEmailType) ? phoneType : [...phoneType, companyDetail?.secondEmailType]).find(
                                        (item) => item === value
                                      )}
                                      onChange={(e, value) => {
                                        onChange(value);
                                      }}
                                      size="small"
                                      readOnly={!isEdit}
                                      options={(phoneType.includes(companyDetail?.secondEmailType) ? phoneType : [...phoneType, companyDetail?.secondEmailType])}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Type "
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={
                                            errors.secondEmailType !== undefined
                                          }
                                          helperText={
                                            errors.secondEmailType !== undefined
                                              ? errors.secondEmailType.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Controller
                                control={control}
                                name="countryCode"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                      disablePortal
                                      id="countryCode"
                                      getOptionLabel={(option) =>
                                        `(${option.value}) ${option.label}`
                                      }
                                      value={country.find(
                                        (item) => item?.value === value
                                      )}
                                      onChange={(e, value) =>{ 
                                         onChange(value?.value)
                                        if (value) {
                                          setPhoneNumber(value?.value);
                                        }
                                      }
                                
                                      }
                                      options={country}
                                      size="small"
                                      readOnly={!isEdit}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Country Code "
                                          // defaultValue={""}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={
                                            errors.countryCode !== undefined
                                          }
                                          helperText={
                                            errors.countryCode !== undefined
                                              ? errors.countryCode.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                            </Grid>{" "}
                            <Grid item xs={12} md={4}>
                              <TextField
                                size="small"
                                fullWidth
                                label="Phone 1"
                                id="phoneNumber"
                                {...register("phoneNumber")}
                                error={errors.phoneNumber !== undefined}
                                helperText={
                                  errors.phoneNumber !== undefined
                                    ? errors.phoneNumber.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={isLoading}
                                InputProps={{
                                  readOnly: !isEdit,
                                      className: !isEdit ? "bg-datepicker-readonly" : "",
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <p>{phoneNumber}</p>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {companyDetail && <Controller
                                control={control}
                                name="phoneType"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                    freeSolo
                                    autoSelect
                                      disablePortal
                                      disableClearable
                                      id="phoneType"
                                      getOptionLabel={(option) => option}
                                      value={(phoneType.includes(companyDetail?.phoneType) ? phoneType : [...phoneType, companyDetail?.phoneType]).find(
                                        (item) => item === value
                                      )}
                                      onChange={(e, value) => {
                                        onChange(value);
                                      }}
                                      size="small"
                                      readOnly={!isEdit}
                                      options={(phoneType.includes(companyDetail?.phoneType) ? phoneType : [...phoneType, companyDetail?.phoneType])}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Type"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={errors.phoneType !== undefined}
                                          helperText={
                                            errors.phoneType !== undefined
                                              ? errors.phoneType.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Controller
                                control={control}
                                name="countryCodeSecond"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                      disablePortal
                                      id="countryCodeSecond"
                                      getOptionLabel={(option) =>
                                        `(${option.value}) ${option.label}`
                                      }
                                      value={country.find(
                                        (item) => item?.value === value
                                      )}
                                      onChange={(e, value) =>{ 
                                         onChange(value?.value)
                                        if (value) {
                                          setPhoneNumber(value?.value);
                                        }
                                      }
                                
                                      }
                                      options={country}
                                      size="small"
                                      readOnly={!isEdit}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Country Code "
                                          // defaultValue={""}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={
                                            errors.countryCodeSecond !== undefined
                                          }
                                          helperText={
                                            errors.countryCodeSecond !== undefined
                                              ? errors.countryCodeSecond.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                            </Grid>{" "}
                            <Grid item xs={12} md={4}>
                              <TextField
                                size="small"
                                fullWidth
                                label="Phone 2"
                                id="phoneNumberSecond"
                                {...register("phoneNumberSecond")}
                                error={errors.phoneNumberSecond !== undefined}
                                helperText={
                                  errors.phoneNumberSecond !== undefined
                                    ? errors.phoneNumberSecond.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={isLoading}
                                InputProps={{
                                  readOnly: !isEdit,
                                      className: !isEdit ? "bg-datepicker-readonly" : "",
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <p>{phoneNumber2}</p>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {companyDetail && <Controller
                                control={control}
                                name="phoneTypeSecond"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                    freeSolo
                                    autoSelect
                                      disablePortal
                                      disableClearable
                                      id="phoneTypeSecond"
                                      getOptionLabel={(option) => option}
                                      value={phoneType.find(
                                        (item) => item === value
                                      )}
                                      onChange={(e, value) => {
                                        onChange(value);
                                      }}
                                      size="small"
                                      readOnly={!isEdit}
                                      options={phoneType}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Type"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={errors.phoneTypeSecond !== undefined}
                                          helperText={
                                            errors.phoneTypeSecond !== undefined
                                              ? errors.phoneTypeSecond.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                size="small"
                                fullWidth
                                label="Front End Url"
                                id="frontEndUrl"
                                {...register("frontEndUrl")}
                                error={errors.frontEndUrl !== undefined}
                                helperText={
                                  errors.frontEndUrl !== undefined
                                    ? errors.frontEndUrl.message
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={isLoading}
                                InputProps={{
                                  readOnly: !isEdit,
                                      className: !isEdit ? "bg-datepicker-readonly" : "",
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Language fontSize="medium" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                control={control}
                                name="mainContactId"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                      disablePortal
                                      disableClearable
                                      id="mainContactId"
                                      getOptionLabel={(option) => {return `${option.firstName} ${option.middleName ? option.middleName : ""} ${option.lastName}`}}
                                      value={listContactCompany.find((item) => item?.id === value)}
                                      onChange={(e, value) => {
                                        onChange(value.id);
                                      }}
                                      size="small"
                                      readOnly={!isEdit}
                                      options={listContactCompany}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Main Contact"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={
                                            errors.mainContactId !== undefined
                                          }
                                          helperText={
                                            errors.mainContactId !== undefined
                                              ? errors.mainContactId.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                           <AddressComponent control={control} register={register} errors={errors} isLoading={isLoading} isEdit={isEdit} />
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <LoadingButton
                      style={{ display: "none" }}
                      ref={refButtonSubmit}
                      className={"manual-button"}
                      disabled={isLoading}
                      type={"submit"}
                      startIcon={<span></span>}
                      loadingPosition={"start"}
                      loading={isLoading}
                    >
                      Save
                    </LoadingButton>
                  </form>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4} sx={{ mb: 1, pb: 1 }}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "0.75rem",
                  }}
                >
                  <ContactComponent
                    selectedCompany={selectedCompany}
                    isEdit={isEdit}
                  />
                </Paper>
                <Paper
                  sx={{
                    my: 1,
                    p: 2,
                    borderRadius: "0.75rem",
                  }}
                >
                  <EmailComponent
                    selectedCompany={selectedCompany}
                    isEdit={isEdit}
                  />
                </Paper>
                <Paper
                  sx={{
                    my: 1,
                    p: 2,
                    borderRadius: "0.75rem",
                  }}
                >
                  <NotesComponent
                    selectedCompany={selectedCompany}
                    isEdit={isEdit}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      )}
      <DialogActions sx={{ p: 2 }}>
        {isEditButton && (
          <LoadingButton
            onClick={() => setIsEdit(!isEdit)}
            variant="contained"
            sx={{
              bgcolor: isEdit ? "#808080" : "#0094d3",
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            disabled={isLoading}
            type={"submit"}
          >
            {isEdit ? "Cancel" : "Edit"}
          </LoadingButton>
        )}
        {isEdit && (
          <LoadingButton
            onClick={() => refButtonSubmit.current?.click()}
            className={"manual-button"}
            disabled={isLoading}
            type={"submit"}
            startIcon={<span></span>}
            loadingPosition={"start"}
            loading={isLoading}
          >
            Save
          </LoadingButton>
        )}
      </DialogActions>
    </Drawer>
  );
};

export default EditCompanyModal;
