import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";

import ListItemIcon from "@mui/material/ListItemIcon";

import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo-tkg.png";
import { responseSendSignInCode, userAuth } from "../interfaces/authentication";
import userService from "../services/userService";

import TKGTreeView from "../components/layouts/menu";
const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));



const AppLayout = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") == null) {
      navigate("/sign-in");
    } else {
      if(localStorage.getItem("user") == null){
        userService
        .getCurrent()
        .then((res) => {
          let authData: responseSendSignInCode = res.data;
          localStorage.setItem("user", JSON.stringify(authData));
          setIsLoading(false);
        })
        .catch((e) => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          navigate('/sign-in');
        });
      } else{ setIsLoading(false)}
     
    }
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    navigate("/sign-in");
  };

  return (
    <Box sx={{ display: "flex" }}>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff",background: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={handleClose}
        >
          <CircularProgress style={{color: 'black'}} />
        </Backdrop>
      ) : (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{ backgroundColor: "white", color: "#6b7280", fontSize: 16,
            fontWeight: 500, boxShadow: "unset", borderBottom: "1px solid #0000001f" }}
          >
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span></span>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                onClick={handleClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  boxShadow: "unset"
                }}
              >
                <Avatar sx={{ bgcolor: "#ff9800", marginRight: "10px" }}>
                  
                  {
                    user.avatarUrl !== null && user.avatarUrl.length > 0 ? 
                    <img
                    width={300}
                    height={300}
                    src={`${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${user.avatarUrl}`}
                    alt="avatar"
                    className="img-ratio rounded-4"
                    style={{ objectFit: "cover", overflow: "hidden"}}
                  /> : <span>
                    {user.firstName.charAt(0)}
                    {user.lastName.charAt(0)}
                  </span>
                  }
                </Avatar>
                {user.firstName} {user.lastName}
              </Box>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>


          
          <Drawer
            PaperProps={{
              sx: {
                background: "linear-gradient(195deg, rgb(42, 63, 81), #233340)",
                color: "white",
              },
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <img
                src={Logo}
                alt=""
                style={{ width: "75%", margin: "0 auto" }}
              />
              {/*<IconButton onClick={handleDrawerClose} sx={{display: "none"}}>*/}
              {/*    {theme.direction === 'ltr' ? <ChevronLeftIcon style={{color: "white"}} /> : <ChevronRightIcon style={{color: "white"}} />}*/}
              {/*</IconButton>*/}
            </DrawerHeader>
            <Divider />
            <TKGTreeView />
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
            <Box
              component={"div"}
              // style={{
              //   backgroundColor: "white",
              //   padding: "10px",
              //   borderRadius: "10px",
              // }}
            >
              <Outlet />
            </Box>
          </Main>
        </>
      )}
    </Box>
  );
};

export default AppLayout;
